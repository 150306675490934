import { Assignment } from "../models/assignment.model"

export const createAssignmentAdapter = (endpointAssign: any): Assignment => {
  
  const adaptedAssignment: Assignment = {
    id: endpointAssign.id || '',
    employeeId: endpointAssign.employee?.id || '',
    asignationPercentage: endpointAssign.asignationPercentage || 0,
    workPosition: endpointAssign.workPosition?.id || '',
    skills: endpointAssign.skills || '',
    seniority: endpointAssign.seniority?.id || '',
    startDate: endpointAssign.startDate || '',
    endDate: endpointAssign.endDate || '',
    amount: endpointAssign.amount || 0,
    iteration: endpointAssign.iteration || 0,
    // TODO cambiar informacion que provenga desde backend
    name: endpointAssign.employee?.firstName || '',
    firstSurname: endpointAssign.employee?.firstSurname || '',
    workPositionName: endpointAssign.workPosition?.name || '',
    seniorityName: endpointAssign.seniority?.name || '',
    currencyId: endpointAssign.currency?.id || ''
  }

  return adaptedAssignment;
}