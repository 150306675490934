import moment from "moment-timezone";
import { Employee } from '../../../interfaces/employee-class/employee.model';



export const createEmployeeAdapter = (endpointData: any) => {

  const adaptedEmployee: Employee = {
    id: endpointData.id,
    CI: endpointData.CI,
    CIType: endpointData.CIType,
    firstName: endpointData.firstName,
    secondName: endpointData.secondName,
    thirdName: endpointData.thirdName,
    firstSurname: endpointData.firstSurname,
    secondSurname: endpointData.secondSurname,
    nationality: endpointData.nationality,
    gender: endpointData.gender,
    email: endpointData.email,
    phoneNumber: endpointData.phoneNumber,
    emergencyPhoneNumber: endpointData.emergencyPhoneNumber,
    emergencyPhoneName: endpointData.emergencyPhoneName,
    birthDate: moment.utc(endpointData.birthDate).format('YYYY-MM-DD') || '',
    entryDate: moment.utc(endpointData.entryDate).format('YYYY-MM-DD') || '',
    departureDate: moment.utc(endpointData.departureDate).format('YYYY-MM-DD') || '',
    country: endpointData.country,
    address: endpointData.address,
    commune: endpointData.commune,
    region: endpointData.region,
    availableForTravel: endpointData.availableForTravel,
    activeEmployee: endpointData.activeEmployee,
  }

  

  return adaptedEmployee;
}