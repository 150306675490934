import { FormEmployee } from "../models/FormEmployee.model";

export const createFormEmployee = (endpointEmployee: any): FormEmployee => {
  const adaptedFormEmployee: FormEmployee = {
    CI: endpointEmployee.CI,
    CIType: endpointEmployee.CIType,
    firstName: endpointEmployee.firstName,
    secondName: endpointEmployee.secondName,
    thirdName: endpointEmployee.thirdName,
    firstSurname: endpointEmployee.firstSurname,
    secondSurname: endpointEmployee.secondSurname,
    nationality: endpointEmployee.nationality,
    gender: endpointEmployee.gender,
    email: endpointEmployee.email,
    phoneNumber: endpointEmployee.phoneNumber,
    emergencyPhoneNumber: endpointEmployee.emergencyPhoneNumber,
    emergencyPhoneName: endpointEmployee.emergencyPhoneName,
    birthDate: new Date(endpointEmployee.birthDate),
    entryDate: new Date(endpointEmployee.entryDate),
    departureDate: (endpointEmployee.departureDate !== '') ? new Date(endpointEmployee.departureDate) : null,
    country: endpointEmployee.country,
    address: endpointEmployee.address,
    commune: endpointEmployee.commune,
    region: endpointEmployee.region,
    availableForTravel: endpointEmployee.availableForTravel,
    activeEmployee: (endpointEmployee.activeEmployee) ? endpointEmployee.activeEmployee : true,
  }

  return adaptedFormEmployee;
}