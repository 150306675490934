export const SOUTH_AMERICA_GENTILIC = [
  'Chileno',
  'Venezolano', 
  'Colombiano', 
  'Argentino',
  'Ecuatoriano',
  'Peruano',
  'Uruguayo',
  'Boliviano',
  'Brasileño',
  'Puertorriqueño',
  'Haitiano',
  'Paraguayo',
  'Beliceño',
  'Costarricence',
  'Cubano',
  'Salvadoreño',
  'Guatemalteco',
  'Guayanés',
  'Haitiano',
  'hondureño',
  'Mexicano',
  'Nicaraguense',
  'Panameño',
  'Dominicano',
  'Surinamita'
];