export const ROLE_DICTIONARY = {

  BREADCRUMBS_ROLES: {
    ROOT_URI: 'Dashboard Roles',
    FIRST_URI: 'Crear Rol'
  },

  DASHBOARD_ROLES: {

  },

  CREATE_ROLE: {
    JUMBOTRON_LABEL: 'Crear Rol',
    STEP_CREATE_HEADER_LABEL: 'Paso 1) Crear Encabezado de Rol',
    INPUT_LABEL_ROLE_NAME: 'Nombre Rol',
    INPUT_PLACEHOLDER_ROLE_NAME: 'Ej: Administrador, Analista, ...etc.',
    INPUT_LABEL_ROLE_STATUS: 'Estado',
    BUTTON_LABEL_CREATE_ROLE: 'Guardar'
  },

  ASSIGN_ROLE: {
    STEP_ASSIGN_HEADER_LABEL: 'Paso 2) Asignar Permisos de Rol',
  }

}