import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useAppDispatch } from "../../../hooks/hooks";
import { Account } from "../../../interfaces/account/account.model";
import { Currency } from "../../../interfaces/currency/currency.model";
import { ServiceStatusKeys } from "../../../interfaces/service-response/service-status-keys";
import { loaderDismiss, loaderShow } from "../../../redux/slices/loaderSlice";
import { showToast } from "../../../redux/slices/toastSlice";
import { getAllAccounts } from "../../../service/accounts/account.service";
import { getAllCurrencies } from "../../../service/currency/currency.service";
import {
  customAlert, toast
} from "../../../service/notification/toast.service";
import { postProject } from "../../../service/project/project.service";
import { getAllServicesTypes } from "../../../service/services-types/services-types.service";
import { Formulas } from "../../../utilities/formulas.utility";
import { FormProject } from "../models/form-project.model";
import { PROJECT_TYPE_KOH } from "../utilities/project-values.utility";

interface ICreateKeyOnHandForm {
  handleProjectId: Function;
}

const CreateKeyOnHandForm: React.FC<ICreateKeyOnHandForm> = ({
  handleProjectId,
}) => {
  const [currentAccount, setCurrentAccount] = useState<string>("");
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [serviceTypes, setServiceTypes] = useState<any[]>([]);
  const [finalSprintCalc, setFinalSprintCalc] = useState<number>(0);
  const [blockState, setBlockState] = useState<boolean>(false);
  const formulas = new Formulas();
  const dispatch = useAppDispatch();

  const MAX_DATE = "2100-12-31";

  const validationSchema = Yup.object().shape({
    projectTypeId: Yup.string().required("Campo requerido"),
    projectName: Yup.string()
      .matches(
        /^[0-9a-zA-Z-. ]*$/,
        "Solo se permiten letras, números, punto o guión"
      )
      .required("Campo requerido")
      .required("Campo requerido"),
    projectContactName: Yup.string()
      .matches(
        /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
        "Solo se permiten letras para este campo"
      )
      .required("Campo requerido"),
    accountId: Yup.string().required("Campo requerido"),
    projectStartDate: Yup.date()
      .max(new Date(MAX_DATE), "Debe especificar una fecha máxima válida")
      .typeError("Debe especificar una fecha de inicio válida")
      .required("Campo requerido"),
    projectEndDate: Yup.date()
      .max(new Date(MAX_DATE), "Debe especificar una fecha máxima válida")
      .typeError("Debe especificar una fecha de finalización válida")
      .required("Campo requerido"),
    projectTotalDays: Yup.number()
      .typeError("Debe ingresar un número válido")
      .integer("Debe ingresar un número entero")
      .min(0.1, "El total de días debe ser mayor a 0")
      .required("Campo requerido"),
    projectTotalHours: Yup.number()
      .typeError("Debe ingresar un número válido")
      .min(0.1, "El total de horas debe ser mayor a 0")
      .required("Campo requerido"),
    projectIteration: Yup.number()
      .typeError("Debe ingresar un número válido")
      .integer("Debe ingresar un número entero")
      .min(0.1, "El total de sprint debe ser mayor a 0")
      .required("Campo requerido"),
    projectPlanning: Yup.number()
      .typeError("Debe ingresar un número válido")
      .min(0.1, "El total de planning debe ser mayor a 0")
      .required("Campo requerido"),
    projectReview: Yup.number()
      .typeError("Debe ingresar un número válido")
      .min(0.1, "El total de review debe ser mayor a 0")
      .required("Campo requerido"),
    projectDaily: Yup.number()
      .typeError("Debe ingresar un número válido")
      .min(0.1, "El total de daily debe ser mayor a 0")
      .required("Campo requerido"),
    projectNumberEmployee: Yup.number()
      .integer("Debe ingresar un número entero")
      .typeError("Debe ingresar un número válido")
      .min(0.1, "El total de colaboradores debe ser mayor a 0")
      .required("Campo requerido"),
    projectTotalIteration: Yup.number()
      .typeError("Debe ingresar un número válido")
      .min(0.1, "El total de sprint final debe ser mayor a 0")
      .required("Campo requerido"),
    projectCurrencyId: Yup.string().required("Debe seleccionar una divisa"),
    projectAmount: Yup.number()
      .typeError("Debe ingresar un número válido")
      .min(0.1, "La tarifa de proyecto debe ser mayor a 0")
      .required("Campo requerido"),
    serviceType: Yup.string().required("Campo requerido")
  });

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormProject>({
    resolver: yupResolver(validationSchema),
  });

  const planningField = useController({ name: "projectPlanning", control });
  const reviewField = useController({ name: "projectReview", control });
  const totalDaysField = useController({ name: "projectTotalDays", control });
  const totalHoursField = useController({ name: "projectTotalHours", control });
  const sprintField = useController({ name: "projectIteration", control });
  const dailyField = useController({ name: "projectDaily", control });
  const effectiveDaysField = useController({
    name: "projectDaysIteration",
    control,
  });
  const sprintsField = useController({
    name: "projectIterationEmployee",
    control,
  });
  const totalEmployeesField = useController({
    name: "projectNumberEmployee",
    control,
  });
  const finalSprintField = useController({
    name: "projectTotalIteration",
    control,
  });
  const startDateField = useController({ name: "projectStartDate", control });
  const endDateField = useController({ name: "projectEndDate", control });

  const handleTotalDays = (ev: any) => {
    const totalHoursCalc = formulas.totalHours(ev.target.value);
    totalHoursField.field.onChange(totalHoursCalc);
    setValue("projectTotalHours", totalHoursCalc);
    return totalDaysField.field.onChange(ev.target.value);
  };

  const handleTotalHours = (ev: any) => {
    const totalDaysCalc = formulas.daysPerHour(ev.target.value);
    totalDaysField.field.onChange(totalDaysCalc);
    setValue("projectTotalDays", totalDaysCalc);
    return totalHoursField.field.onChange(ev.target.value);
  };

  const createProject = (data: FormProject) => {
    customAlert(
      "¿Deseas guardar todos los cambios efectuados en el formulario?"
    ).then((res) => {
      if (res.isConfirmed) {
        dispatch(loaderShow());
        postProject(data).then((response) => {
          dispatch(loaderDismiss());
          if (response.status === ServiceStatusKeys.SUCCESS) {
            setBlockState(true);
            handleProjectId({
              id: response.data.id,
              accountId: response.data.account.id,
              iterations: Math.ceil(finalSprintField.field.value),
            });
            dispatch(showToast({ message: 'El proyecto se ha creado exitosamente', type: 'success' }))
          }
          if (response.status === ServiceStatusKeys.FAIL)
            dispatch(showToast({ message: 'Hubo un error al crear proyecto', type: 'error' }))
        });
      }
    });
  };

  const calcEDays = (
    sprint: number,
    planning: number,
    review: number,
    daily: number
  ) => {
    const effectiveDaysCalc = formulas.calcEffectiveDays(
      daily,
      sprint,
      planning,
      review
    );
    setValue("projectDaysIteration", effectiveDaysCalc, {
      shouldValidate: true,
    });
    return effectiveDaysField.field.onChange(effectiveDaysCalc);
  };

  const calcSprints = (totalDays: number, effectiveDays: number) => {
    const sprintCalc = formulas.sprintCalc(totalDays, effectiveDays);
    setValue("projectIterationEmployee", sprintCalc, { shouldValidate: true });
    return sprintsField.field.onChange(sprintCalc);
  };

  const calcFinalSprints = (sprints: number, totalEmployees: number) => {
    const totalIterationCalc = formulas.finalSprintCalc(
      sprints,
      totalEmployees
    );
    setValue("projectTotalIteration", totalIterationCalc, {
      shouldValidate: true,
    });
    setFinalSprintCalc(totalIterationCalc);
    return finalSprintField.field.onChange(totalIterationCalc);
  };

  const loadAccounts = async () => {
    dispatch(loaderShow());
    const accountsList = await getAllAccounts();
    const serviceTypesList = await getAllServicesTypes();

    if(
      accountsList.status === ServiceStatusKeys.SUCCESS &&
      serviceTypesList.status === ServiceStatusKeys.SUCCESS
    ) {
      setAccounts(accountsList.data);
      setServiceTypes(serviceTypesList.data);
    }

    return dispatch(loaderDismiss());
  }

  const loadCurrencies = async (accountId: string) => {
    dispatch(loaderShow());
    const currenciesList = await getAllCurrencies(accountId);

    if(currenciesList.status === ServiceStatusKeys.SUCCESS) {
      setCurrencies(currenciesList.data);
    }

    return dispatch(loaderDismiss());
  }

  useEffect(() => {
    loadAccounts()
  }, []);

  useEffect(() => {
    if (currentAccount !== "") {
      loadCurrencies(currentAccount);
    }
  }, [currentAccount]);

  useEffect(() => {
    if (
      sprintField.field.value &&
      planningField.field.value &&
      reviewField.field.value &&
      dailyField.field.value
    ) {
      calcEDays(
        Number(sprintField.field.value),
        Number(planningField.field.value),
        Number(reviewField.field.value),
        Number(dailyField.field.value)
      );
    }
  }, [
    sprintField.field.value,
    planningField.field.value,
    reviewField.field.value,
    dailyField.field.value,
  ]);

  useEffect(() => {
    if (totalDaysField.field.value && effectiveDaysField.field.value) {
      calcSprints(
        Number(totalDaysField.field.value),
        Number(effectiveDaysField.field.value)
      );
    }
  }, [totalDaysField.field.value, effectiveDaysField.field.value]);

  useEffect(() => {
    if (totalEmployeesField.field.value && sprintsField.field.value) {
      calcFinalSprints(
        Number(sprintsField.field.value),
        Number(totalEmployeesField.field.value)
      );
    }
  }, [totalEmployeesField.field.value, sprintsField.field.value]);

  return (
    <form onSubmit={handleSubmit(createProject)}>
      <input
        type="hidden"
        {...register("projectTypeId")}
        value={PROJECT_TYPE_KOH}
      />

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Nombre de Proyecto</label>
            <input
              {...register("projectName")}
              className={`form-control ${
                errors.projectName ? "is-invalid" : ""
              }`}
              disabled={blockState}
              type="text"
              placeholder="Ej: Capacity Calculator 2.0"
            />
            <div className="invalid-feedback">
              {errors.projectName?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Contacto</label>
            <input
              {...register("projectContactName")}
              className={`form-control ${
                errors.projectContactName ? "is-invalid" : ""
              }`}
              disabled={blockState}
              type="text"
              placeholder="Ej: Luis Poblete"
            />
            <div className="invalid-feedback">
              {errors.projectContactName?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Cliente</label>
            <select
              {...register("accountId")}
              className={`form-control form-select ${
                errors.accountId ? "is-invalid" : ""
              }`}
              disabled={blockState}
              onChange={(ev) => {
                setValue("accountId", ev.target.value, {
                  shouldValidate: true,
                });
                setCurrentAccount(ev.target.value);
              }}
            >
              <option value="">Indique el cliente</option>
              {accounts.length > 0 &&
                accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
            </select>
            <div className="invalid-feedback">{errors.accountId?.message}</div>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Fecha Inicio</label>
            <input
              type="date"
              className={`form-control ${
                errors.projectStartDate ? "is-invalid" : ""
              }`}
              value={startDateField.field.value?.toString()}
              onChange={(ev) => startDateField.field.onChange(ev.target.value)}
              disabled={blockState}
            />
            <div className="invalid-feedback">
              {errors.projectStartDate?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Fecha Finalización</label>
            <input
              type="date"
              value={endDateField.field.value?.toString()}
              onChange={(ev) => endDateField.field.onChange(ev.target.value)}
              className={`form-control ${
                errors.projectEndDate ? "is-invalid" : ""
              }`}
              disabled={blockState}
              min={startDateField.field.value?.toString()}
            />
            <div className="invalid-feedback">
              {errors.projectEndDate?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Días Totales</label>
            <input
              className={`form-control ${
                errors.projectTotalDays ? "is-invalid" : ""
              }`}
              type="number"
              disabled={blockState}
              min={-0.0}
              step={0.01}
              value={totalDaysField.field.value}
              onChange={handleTotalDays}
              placeholder="Ej: 31"
            />
            <div className="invalid-feedback">
              {errors.projectTotalDays?.message}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label d-flex align-items-center gap-2">
              Horas Totales
              <i
                className="bi bi-info-circle-fill"
                data-bs-toggle="tooltip"
                title="Duración máxima del proyecto en Horas"
                style={{ cursor: "pointer", color: "gray" }}
              ></i>
            </label>
            <input
              className={`form-control ${
                errors.projectTotalHours ? "is-invalid" : ""
              }`}
              placeholder="Ej: 248"
              disabled={blockState}
              type="number"
              step={0.01}
              value={totalHoursField.field.value}
              onChange={handleTotalHours}
            />
            <div className="invalid-feedback">
              {errors.projectTotalHours?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label d-flex align-items-center gap-2">
              Sprint
              <i
                className="bi bi-info-circle-fill"
                data-bs-toggle="tooltip"
                title="Duración máxima de una iteración, Ej: 2 semanas"
                style={{ cursor: "pointer", color: "gray" }}
              ></i>
            </label>
            <input
              className={`form-control ${
                errors.projectIteration ? "is-invalid" : ""
              }`}
              disabled={blockState}
              type="number"
              placeholder="Ej: 2"
              step={0.01}
              value={sprintField.field.value}
              onChange={(ev) => sprintField.field.onChange(ev.target.value)}
            />
            <span className="form-text">Ej: 2 (semanas)</span>
            <div className="invalid-feedback">
              {errors.projectIteration?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label d-flex align-items-center gap-2">
              Planning
              <i
                className="bi bi-info-circle-fill"
                data-bs-toggle="tooltip"
                title="Total de horas por sprint para crear una planning"
                style={{ cursor: "pointer", color: "gray" }}
              ></i>
            </label>
            <input
              className={`form-control ${
                errors.projectPlanning ? "is-invalid" : ""
              }`}
              type="number"
              placeholder="Ej: 2"
              disabled={blockState}
              step={0.01}
              value={planningField.field.value}
              onChange={(ev) => planningField.field.onChange(ev.target.value)}
            />
            <span className="form-text">Ej: 2 (horas)</span>
            <div className="invalid-feedback">
              {errors.projectPlanning?.message}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label d-flex align-items-center gap-2">
              Review
              <i
                className="bi bi-info-circle-fill"
                data-bs-toggle="tooltip"
                title="Total de horas por sprint para realizar una review"
                style={{ cursor: "pointer", color: "gray" }}
              ></i>
            </label>
            <input
              className={`form-control ${
                errors.projectReview ? "is-invalid" : ""
              }`}
              disabled={blockState}
              type="number"
              placeholder="Ej: 1"
              step={0.01}
              value={reviewField.field.value}
              onChange={(ev) => reviewField.field.onChange(ev.target.value)}
            />
            <span className="form-text">Ej: 1 (hora)</span>
            <div className="invalid-feedback">
              {errors.projectReview?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label d-flex align-items-center gap-2">
              Daily
              <i
                className="bi bi-info-circle-fill"
                data-bs-toggle="tooltip"
                title="Total de horas por sprint para realizar una daily"
                style={{ cursor: "pointer", color: "gray" }}
              ></i>
            </label>
            <input
              className={`form-control ${
                errors.projectDaily ? "is-invalid" : ""
              }`}
              placeholder="Ej: 0,5"
              disabled={blockState}
              type="number"
              step={0.01}
              value={dailyField.field.value}
              onChange={(ev) => dailyField.field.onChange(ev.target.value)}
            />
            <span className="form-text">Ej: 0,5 (hora)</span>
            <div className="invalid-feedback">
              {errors.projectDaily?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Días Efectivos Sprint</label>
            <input
              className={`form-control ${
                errors.projectDaysIteration ? "is-invalid" : ""
              }`}
              type="number"
              disabled={true}
              step={0.01}
              placeholder="Calculado..."
              value={effectiveDaysField.field.value}
              onChange={(ev) =>
                effectiveDaysField.field.onChange(ev.target.value)
              }
            />
            <div className="invalid-feedback">
              {errors.projectDaysIteration?.message}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Sprints</label>
            <input
              className={`form-control ${
                errors.projectIterationEmployee ? "is-invalid" : ""
              }`}
              type="number"
              disabled={true}
              step={0.01}
              placeholder="Calculado..."
              value={sprintsField.field.value}
              onChange={(ev) => sprintsField.field.onChange(ev.target.value)}
            />
            <div className="invalid-feedback">
              {errors.projectIterationEmployee?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label d-flex align-items-center gap-2">
              Colaboradores
              <i
                className="bi bi-info-circle-fill"
                data-bs-toggle="tooltip"
                title="Total de colaboradores del proyecto"
                style={{ cursor: "pointer", color: "gray" }}
              ></i>
            </label>
            <input
              className={`form-control ${
                errors.projectNumberEmployee ? "is-invalid" : ""
              }`}
              type="number"
              placeholder="Ej: 5"
              disabled={blockState}
              step={0.01}
              value={totalEmployeesField.field.value}
              onChange={(ev) =>
                totalEmployeesField.field.onChange(ev.target.value)
              }
            />
            <div className="invalid-feedback">
              {errors.projectNumberEmployee?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Sprint Final</label>
            <input
              className={`form-control ${
                errors.projectTotalIteration ? "is-invalid" : ""
              }`}
              type="number"
              placeholder="Ej: 3,1"
              disabled={blockState}
              step={0.01}
              value={finalSprintField.field.value}
              onChange={(ev) =>
                finalSprintField.field.onChange(ev.target.value)
              }
            />
            <span className="form-text">
              Campo Calculado: {finalSprintCalc}
            </span>
            <div className="invalid-feedback">
              {errors.projectTotalIteration?.message}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <label className="form-label">Tarifa</label>
          <div className="input-group mb-3">
            <select
              {...register("projectCurrencyId")}
              className={`input-group-text ${
                errors.projectCurrencyId ? "is-invalid" : ""
              }`}
              disabled={blockState}
              onChange={(ev) =>
                setValue("projectCurrencyId", ev.target.value, {
                  shouldValidate: true,
                })
              }
              defaultValue={""}
            >
              <option value="" disabled>
                {/* 💵 */}
                🪙
              </option>
              {currencies.length > 0 &&
                currencies.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.symbol}
                  </option>
                ))}
            </select>
            <input
              placeholder="Ej: 50000"
              type="number"
              disabled={blockState}
              {...register("projectAmount")}
              className={`radio-right-input form-control ${
                errors.projectAmount ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.projectAmount?.message}
            </div>
            <div className="invalid-feedback">
              {errors.projectCurrencyId?.message}
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Tipo de Servicio</label>
            <select
              {...register("serviceType")}
              className={`form-control form-select ${
                errors.serviceType ? "is-invalid" : ""
              }`}
              disabled={blockState}
            >
              <option value="">Indique el tipo servicio</option>
              {
                serviceTypes.length > 0 &&
                serviceTypes.map((serviceType) => (
                  <option key={serviceType.id} value={serviceType.id}>
                    {serviceType.name}
                  </option> 
                ))
              }
            </select>
            <div className="invalid-feedback">{errors.serviceType?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Descripción</label>
            <textarea 
              style={{ resize: 'none' }}
              {...register('description')}
              className={`form-control ${
                errors.description ? "is-invalid" : ""
              }`}
              disabled={blockState}
              cols={30} rows={2}
              maxLength={200}
              placeholder="El proyecto...">
            </textarea>
            <span className="form-text">Opcional</span><br />
            <span className="form-text">Máximo 200 caracteres</span>
            <div className="invalid-feedback">
              {errors.description?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary w-25"
          disabled={blockState}
        >
          Guardar
        </button>
      </div>
    </form>
  );
};

export default CreateKeyOnHandForm;
