import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useBetween } from 'use-between';
import HeaderJumbotron from '../../components/HeaderJumbotron';
import { useAppDispatch } from '../../hooks/hooks';
import { loaderDismiss, loaderShow } from '../../redux/slices/loaderSlice';
import { getRolesPermissionsById } from '../../service/roles-permissions/roles-permissions.service';
import { getRoleById } from '../../service/roles/roles.service';
import useShareableState from '../../utilities/UseShareableState';
import RoleEditAssign from './components/RoleEditAssign';
import RoleEditForm from './components/RoleEditForm';
import { FormRole } from './models/form-role.model';
import { Permission } from './models/permission.model';
import { View } from './models/view.model';

export interface ViewA extends View {
  permissions: Permission[]
}

const UpdateRolePage = () => {
  const { id } = useParams();
  const { activeInput } = useBetween(useShareableState);
  const [roleData, setRoleData] = useState<FormRole>(new FormRole());
  const [viewList, setViewList] = useState<ViewA[]>([]);
  const dispatch = useAppDispatch();

  const loadRoles = async (id: string) => {
    dispatch(loaderShow());
    const roleById = await getRoleById(id);
    const permissionsById = await getRolesPermissionsById(id);

    if(roleById.data && permissionsById.data) {
      const permissionsArray = permissionsById.data.ViewPermission.map((vp: any) => ({ id: vp.viewId, name: vp.name, permissions: vp.permissions }));
      setRoleData(roleById.data);
      setViewList(permissionsArray);
    }
    dispatch(loaderDismiss());
  }
  
  useEffect(() => {
    if(id) {
      loadRoles(id);
    }
  }, []);

  return (
    <>
      <HeaderJumbotron title={(activeInput) ? 'Ver Rol' : 'Editar Rol'}/>

      <div className="container-fluid pt-5">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/roles">
                <span style={{ color: '#FFA645' }}>
                  Dashboard Roles
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">{(activeInput) ? 'Ver Rol' : 'Editar Rol'}</li>
          </ol>
        </nav>

        <div className="card">
          <div className="card-body">
            <h4>Paso 1) Crear Header de Rol</h4>
            <hr />
            <RoleEditForm 
              roleData={ roleData }
            />
          </div>
        </div>

        <div className="card mt-5">
          <div className="card-body">
            <h4>Paso 2) Asignar permisos al Rol</h4>
            <hr />
            <RoleEditAssign
              viewList={ viewList }
            />
          </div>
        </div>

      </div>
    </>
  )
}

export default UpdateRolePage;