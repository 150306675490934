import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { getAllPermissions } from '../../../service/permissions/permissions.service';
import { createPermissionAdapter } from '../adapters/create-permission.adapter';
import { Permission } from '../models/permission.model';

interface IAccessRowItem {
  viewName: string;
  viewId: string;
  editData: Permission[];
  blocked: boolean;
  setPermissions: Function;
}

const AccessRowItemEdit: FC<IAccessRowItem> = ({ 
  viewId, 
  viewName, 
  setPermissions,
  editData,
  blocked
}) => {

  const READ_NAME = 'Ver';
  const dispatch = useAppDispatch();
  const [permissionList, setPermissionList] = useState<Permission[]>([]);

  const handleCheck = (permId: string, isChecked: boolean) => {
    const permissionIndex = permissionList.findIndex(perm => perm.id === permId);
    const permissionReadIndex = permissionList.findIndex(perm => perm.name === READ_NAME);
    let stagedList = permissionList;

    if(permissionIndex === permissionReadIndex) {
      if(!isChecked) {
        stagedList = stagedList.map(perm => ({ ...perm, isActive: false}))
      }

      stagedList[permissionIndex].isActive = isChecked;
      return setPermissionList([...stagedList]);
    }

    if(permissionList[permissionIndex].name !== READ_NAME && !permissionList[permissionReadIndex].isActive ) {
      stagedList[permissionReadIndex].isActive = true;
      stagedList[permissionIndex].isActive = isChecked;
    }

    if(permissionList[permissionReadIndex].isActive) {
      stagedList[permissionIndex].isActive = isChecked;
    }

    return setPermissionList([...stagedList]);
  }

  const loadPermissions = async () => {
    dispatch(loaderShow());
    const loadedPermissions = await getAllPermissions();

    if(loadedPermissions.status === ServiceStatusKeys.SUCCESS) {
      if(editData) {
        const adaptedPermissions = editData.map((perm: any) => createPermissionAdapter(perm));
        setPermissionList(adaptedPermissions);
      }
      if(!editData){
        const adaptedPermissions = loadedPermissions.data.map((perm: any) => createPermissionAdapter(perm));
        setPermissionList(adaptedPermissions.map((perm: any) => ({ ...perm, isActive: false })));
      }
    }
    dispatch(loaderDismiss());
  }

  useEffect(() => {
    loadPermissions();

    if(editData && permissionList.length > 0) {
      const adaptedPermissions = editData.map((perm: any) => createPermissionAdapter(perm));
      setPermissionList(adaptedPermissions.map((perm: any) => ({ ...perm, isActive: false })));
    } 
  }, []);

  useEffect(() => {
    if(permissionList.length > 0) {
      const mappedPermissions = permissionList.map((perm: any) => ({
        viewId: viewId,
        permissionId: perm.id,
        isActive: perm.isActive
      }));

      setPermissions(mappedPermissions, viewId);
    }
  }, [permissionList]);

  return (
    <li className='list-group-item'>
      <div className="row">
        <div className="col-6">
          <span>{ viewName }</span>
        </div>
        <div className="col-6 d-flex justify-content-between">
          {
            permissionList.length > 0 &&
            permissionList.map((perm, index) => (
              <div key={'perm'+index} className="form-check">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  checked={perm.isActive} 
                  disabled={blocked}
                  onChange={(ev) => handleCheck(perm.id, ev.target.checked)}
                />
                <label className="form-check-label">
                  { perm.name }
                </label>
              </div>
            ))
          }
        </div>
      </div>
    </li>
  )
}

export default AccessRowItemEdit