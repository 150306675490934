import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DashboardButtons
} from "../../../config/config";
import { createEmployeeAdapter } from "../adapters/create-employee.adapter";

import { useAppDispatch } from "../../../hooks/hooks";
import { Employee } from '../../../interfaces/employee-class/employee.model';
import { ServiceStatusKeys } from "../../../interfaces/service-response/service-status-keys";
import { loaderDismiss, loaderShow } from "../../../redux/slices/loaderSlice";
import { getEmployees } from "../../../service/employee/employee.service";

const ListCollaborator = () => {

  const [employees, setEmployees] = useState<Employee[]>([]);
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const navigateToEdit = (employeeId: string) => {
    return navigate(`/colaboradores/${employeeId}`,{replace: true});
  }

  const loadEmployees = async () => {
    dispatch(loaderShow());
    const loadedEmployees = await getEmployees();
    if(loadedEmployees.status === ServiceStatusKeys.SUCCESS) {
      setEmployees(loadedEmployees.data.map((employee: any) => createEmployeeAdapter(employee)));
    }
    return dispatch(loaderDismiss());
  }

  useEffect(() => {
    loadEmployees();
  }, []);

  return (
    <div className="table-responsive table-scroll">
    <table className="table text-nowrap mb-0">
      <thead className="table-light table-sticky">
        <tr>
          <th>Identificador</th>
          <th>Nombre</th>
          <th>Apellidos</th>
          <th>Correo</th>
          <th>Fecha de Ingreso</th>
          <th>Teléfono</th>
          <th className="text-center">Acciones</th>
          {/* {ListCollaboratorsCreatedTableHeader.map((list) => {
            return <th key={list}>{list}</th>;
          })} */}
        </tr>
      </thead>
      <tbody>
        {employees.length > 0 &&
          employees.map((employee) => {
            return (
              <tr key={employee.id}>
                <td>{employee.CI}</td>
                <td>{employee.firstName}</td>
                <td>{employee.firstSurname} {employee.secondSurname}</td>
                <td>{employee.email}</td>
                <td>{moment(employee.entryDate).format('DD/MM/YYYY')}</td>
                <td>{employee.phoneNumber}</td>
                <td className="text-center">
                  <button
                    onClick={() => navigateToEdit(employee.id)}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    {DashboardButtons.SEE}
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
  );
};

export default ListCollaborator;
