import { Helmet, HelmetProvider } from "react-helmet-async";

const Head = () => {
  return (
    <div className="Head">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href="@@webRoot/assets/images/favicon/favicon.ico"
          />

          <title>Capacity and Calculator 2.0 | Xintec</title>
          <meta name="description" content="Capacity and Calculator 2.0" />
          <meta name="keywords" content="Capacity, Calculator, Xintec" />
          <meta name="author" content="Xintec" />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
    </div>
  );
};

export default Head;
