import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  DashboardButtons,
  DashboardForms
} from "../../../config/config";
import { useAppDispatch } from "../../../hooks/hooks";
import { ServiceStatusKeys } from "../../../interfaces/service-response/service-status-keys";
import { loaderDismiss, loaderShow } from "../../../redux/slices/loaderSlice";
import { showToast } from "../../../redux/slices/toastSlice";
import { postEmployee } from "../../../service/employee/employee.service";
import {
  customAlert, toast
} from "../../../service/notification/toast.service";
import { SOUTH_AMERICA_GENTILIC } from "../../../utilities/south-america-gentilic.utility";
import { createFormEmployee } from "../adapters/create-form-employee.adapter";
import { FormEmployee } from "../models/FormEmployee.model";
import { validationSchema } from "../utilities/collaborator-validation.utility";

const CreateCollaboratorForm = () => {
  const dispatch = useAppDispatch();
  const [idValidator, setIdValidator] = useState<string>("Ej: 12345678-9");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormEmployee>({
    resolver: yupResolver(validationSchema),
  });

  const handleCreateCollaborator = (data: FormEmployee) => {
    customAlert("¿Deseas guardar a este colaborador?").then((res) => {
      if (res.isConfirmed) {
        dispatch(loaderShow());
        postEmployee(createFormEmployee(data)).then((response) => {
          dispatch(loaderDismiss());
          if (response.status === ServiceStatusKeys.SUCCESS) {
            navigate('/colaboradores');
            dispatch(showToast({ message: 'Se ha creado al colaborador exitosamente', type: 'success' }))
          }
          if (response.status === ServiceStatusKeys.FAIL) {
            dispatch(showToast({ message: 'Hubo un error al crear al colaborador', type: 'error' }))
          }
        });
      }
    });
  };

  const handleIdPlaceholder = (option: string) => {
    if (option === "run") return setIdValidator("Ej: 12345678-9");
    if (option === "dni") return setIdValidator("Ej: 12345678A");
    if (option === "passport") return setIdValidator("Ej: A12345678");
    return false;
  };

  const entryDateField = useController({ name: "entryDate", control });
  const departureDateField = useController({ name: "departureDate", control });

  return (
    <form onSubmit={handleSubmit(handleCreateCollaborator)}>
      <h4 className="mb-4">Identificación</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.CI_TYPE}</label>
            <select
              {...register("CIType")}
              className={`form-control form-select ${
                errors.CIType ? "is-invalid" : ""
              }`}
              onChange={(ev) => handleIdPlaceholder(ev.target.value)}
            >
              <option value="">Indique el tipo de documento</option>
              <option value="run">RUN</option>
              <option value="dni">DNI</option>
              <option value="passport">Pasaporte</option>
            </select>
            <div className="invalid-feedback">{errors.CIType?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.CI}</label>
            <input
              type="text"
              placeholder={idValidator}
              {...register("CI")}
              className={`form-control ${errors.CI ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.CI?.message}</div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Datos Personales</h4>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              {DashboardForms.COLABORATOR_NAME}
            </label>
            <input
              type="text"
              placeholder="Ingrese Nombre"
              {...register("firstName")}
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.SECOND_NAME}</label>
            <input
              type="text"
              placeholder="Ingrese Segundo Nombre"
              {...register("secondName")}
              className={`form-control ${
                errors.secondName ? "is-invalid" : ""
              }`}
            />
            <span className="form-text">Opcional</span>
            <div className="invalid-feedback">{errors.secondName?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.THIRD_NAME}</label>
            <input
              type="text"
              placeholder="Ingrese Tercer Nombre"
              {...register("thirdName")}
              className="form-control"
            />
            <span className="form-text">Opcional</span>
            <div className="invalid-feedback">{errors.thirdName?.message}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.FIRST_SURNAME}</label>
            <input
              type="text"
              placeholder="Ingrese Apellido"
              {...register("firstSurname")}
              className={`form-control ${
                errors.firstSurname ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.firstSurname?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              {DashboardForms.SECOND_SURNAME}
            </label>
            <input
              type="text"
              placeholder="Ingrese Segundo Apellido"
              {...register("secondSurname")}
              className={`form-control ${
                errors.secondSurname ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.secondSurname?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.NATIONALITY}</label>
            <select
              placeholder="Ingrese Nacionalidad"
              {...register("nationality")}
              className={`form-control form-select ${
                errors.nationality ? "is-invalid" : ""
              }`}
            >
              <option value="">Seleccione Nacionalidad</option>
              {SOUTH_AMERICA_GENTILIC.map((people, index) => (
                <option key={"gent" + index} value={people}>
                  {people}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {errors.nationality?.message}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.BIRTHDATE}</label>
            <input
              type="date"
              {...register("birthDate")}
              className={`form-control ${errors.birthDate ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.birthDate?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.GENDER}</label>
            <select
              {...register("gender")}
              className={`form-control form-select ${
                errors.gender ? "is-invalid" : ""
              }`}
            >
              <option value="">Indique su género</option>
              <option value="masculino">Masculino</option>
              <option value="femenino">Femenino</option>
              <option value="indefinido">Indefinido</option>
              <option value="otro">Otro</option>
            </select>
            <div className="invalid-feedback">{errors.gender?.message}</div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Contacto Colaborador</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-4">
            <label className="form-label">{DashboardForms.EMAIL}</label>
            <input
              type="email"
              placeholder="ejemplo@xintec.cl"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.PHONE_NUMBER}</label>
            <input
              type="text"
              placeholder="Ej: +56912345678"
              {...register("phoneNumber")}
              className={`form-control ${
                errors.phoneNumber ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.phoneNumber?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Domicilio</h4>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.ADDRESS}</label>
            <input
              type="text"
              placeholder="Ingrese Dirección"
              {...register("address")}
              className={`form-control ${errors.address ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.COMMUNE}</label>
            <input
              type="text"
              placeholder="Ingrese Comuna"
              {...register("commune")}
              className={`form-control ${errors.commune ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.commune?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.REGION}</label>
            <input
              type="text"
              placeholder="Ingrese Región"
              {...register("region")}
              className={`form-control ${errors.region ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.region?.message}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.COUNTRY}</label>
            <input
              type="text"
              placeholder="Ingrese País"
              {...register("country")}
              className={`form-control ${errors.country ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.country?.message}</div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Contacto de Emergencias</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">
              {DashboardForms.EMERGENCY_PHONENAME}
            </label>
            <input
              type="text"
              placeholder="Nombre Contacto Emergencia"
              {...register("emergencyPhoneName")}
              className={`form-control ${
                errors.emergencyPhoneName ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.emergencyPhoneName?.message}
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">
              {DashboardForms.EMERGENCY_NUMBER}
            </label>
            <input
              type="text"
              placeholder="Ej: +56940554433"
              {...register("emergencyPhoneNumber")}
              className={`form-control ${
                errors.emergencyPhoneNumber ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.emergencyPhoneNumber?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Admisión</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.ENTRYDATE}</label>
            <input
              type="date"
              value={entryDateField.field.value?.toString()}
              onChange={(ev) => entryDateField.field.onChange(ev.target.value)}
              className={`form-control ${errors.entryDate ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.entryDate?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">{DashboardForms.DEPARTUREDATE}</label>
            <input
              type="date"
              value={departureDateField.field.value?.toString()}
              onChange={(ev) =>
                departureDateField.field.onChange(ev.target.value)
              }
              className={`form-control ${
                errors.departureDate ? "is-invalid" : ""
              }`}
              min={entryDateField.field.value?.toString()}
            />
            <span className="form-text">Opcional</span>
            <div className="invalid-feedback">
              {errors.departureDate?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-end">
        <button className="btn btn-primary w-25" type="submit">
          {DashboardButtons.SAVE}
        </button>
      </div>
    </form>
  );
};

export default CreateCollaboratorForm;
