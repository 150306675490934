import { useEffect, useState } from 'react';
import HeaderJumbotron from '../../components/HeaderJumbotron'
import { getLocalStorage } from '../../service/local-storage/storage.service';

const HomePage = () => {
  const [userData, setUserData] = useState<any>(getLocalStorage('authData'));
  const [userName, setUserName] = useState<string>('');

  useEffect(() => {
    setUserName(userData.name.split(' ')[0])
  }, [userData]);
  
  return (
    <>
      <HeaderJumbotron title={'Página Principal'} />
      <div className="container-fluid pt-5 ps-5">
        <h3 className='text-secondary fw-bolder'>Hola {userName},</h3>
        <p className='fs-4'>
          Bienvenid@ a la aplicación "Capacity and Calculator 2.0", 
          una plataforma de administración de proyectos de la empresa Xintec, posee además, 
          administración de Colaboradores.
        </p>
        <p className='fs-4'>
          La barra lateral izquierda, muestra las vistas donde puedes ingresar a las áreas de operación de la plataforma.
        </p>
      </div>
    </>
  )
}

export default HomePage;