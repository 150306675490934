import { Link } from "react-router-dom";
import HeaderJumbotron from "../../components/HeaderJumbotron";
import ListProjects from "./components/ListProjects";
import { DashboardButtons, DashboardTitles } from "../../config/config";
import { checkRolePermissions } from "../../utilities/check-role-permissions.utility";
import { VIEW_NAMES } from "../../utilities/view-names";
import { PERMISSIONS } from "../../utilities/permissions";

const ListProjectPage = () => {

  return (
    <>
      <HeaderJumbotron title={DashboardTitles.PROJECT_DASHBOARD} />

      <div className="container-fluid pt-5">

        {
          checkRolePermissions(VIEW_NAMES.PROJECTS, PERMISSIONS.WRITE) &&
          <div className="d-md-flex justify-content-md-end mb-5">
            <Link to="nuevo">
              <button className="btn btn-primary mx-1" type="button">
                {DashboardButtons.CREATE_PROJECT}
              </button>
            </Link>
          </div>
        }


        <div className="card">
          <div className="card-body">
            <ListProjects />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListProjectPage;
