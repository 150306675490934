import * as yup from "yup";

const MAX_DATE = "2100-12-31";

export const projectValidation = yup.object().shape({
  typeId: yup.string().required("Campo requerido"),
  status: yup.string().required("Campo requerido"),
  name: yup
    .string()
    .matches(
      /^[0-9a-zA-Z-ÁÉÍÓÚáéíóúñÑ. ]*$/,
      "Solo se permiten letras y números punto y guión"
    )
    .required("Campo requerido"),
  contactName: yup
    .string()
    .matches(
      /^[0-9a-zA-Z ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  accountId: yup.string().required("Campo requerido"),
  startDate: yup
    .date()
    .max(new Date(MAX_DATE), "Debe especificar una fecha máxima válida")
    .typeError("Debe especificar una fecha de inicio válida")
    .required("Campo requerido"),
  endDate: yup
    .date()
    .max(new Date(MAX_DATE), "Debe especificar una fecha máxima válida")
    .typeError("Debe especificar una fecha de finalización válida")
    .required("Campo requerido"),
  totalDays: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .integer("Debe ingresar un número entero")
    .min(0.1, "El total de días debe ser mayor a 0")
    .required("Campo requerido"),
  totalHours: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .min(0.1, "El total de horas debe ser mayor a 0")
    .required("Campo requerido"),
  iteration: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .integer("Debe ingresar un número entero")
    .min(0.1, "El total de sprint debe ser mayor a 0")
    .required("Campo requerido"),
  planning: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .min(0.1, "El total de planning debe ser mayor a 0")
    .required("Campo requerido"),
  review: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .min(0.1, "El total de review debe ser mayor a 0")
    .required("Campo requerido"),
  daily: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .min(0.1, "El total de daily debe ser mayor a 0")
    .required("Campo requerido"),
  numberEmployee: yup
    .number()
    .min(.1, "El total de colaboradores debe ser mayor a 0")
    .integer("Debe ingresar un número entero mayor a 0")
    .typeError("Debe ingresar un número válido")
    .required("Campo requerido"),
  totalIteration: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .min(0.1, "El total de sprint final debe ser mayor a 0")
    .required("Campo requerido"),
  amount: yup
    .number()
    .typeError("Debe ingresar un número válido")
    .min(0.1, "La tarifa de proyecto debe ser mayor a 0")
    .required("Campo requerido"),
  currencyId: yup.string().required("Campo requerido"),
  serviceType: yup.string().required("Campo requerido")
});
