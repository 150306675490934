import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createSSOAdapter } from "../../adapter/single-sign-on/createSSO.adapter";
import { useAppDispatch } from "../../hooks/hooks";
import { showToast } from "../../redux/slices/toastSlice";
import { setLocalStorage } from '../../service/local-storage/storage.service';

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <div className="container d-flex flex-column">
      <div
        className="row align-items-center justify-content-center g-0
        min-vh-100"
      >
        <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
          <div className="card smooth-shadow-md">
            <img
              alt="Logo Xintec"
              src="/theme/assets/images/brand/logo/xintec-logo-alta.png"
              className="img-fluid"
            />
            <hr />
            <div className="card-body p-6">
              <div className="mb-4">
                <h3 className="mb-0 fw-bold text-center">
                  Bienvenido a Xintec APP
                </h3>
                <p className="mb-6 mt-3 text-center">Login</p>
              </div>
              <div className="app mt-2 d-flex justify-content-center mb-3">
                <GoogleOAuthProvider clientId="949281383242-finh8ibnnu75c8kuhepve3g5mlh8330q.apps.googleusercontent.com">
                  <div>
                    <GoogleLogin
                      useOneTap
                      onSuccess={async (credentialResponse) => {
                        const uninterceptedAxiosInstance = axios.create();
                        const response = await uninterceptedAxiosInstance.post(apiUrl + "login", {
                          token: credentialResponse.credential,
                        });

                        if(!response.data.ViewPermission) {
                          dispatch(showToast({ 
                            title:'Usuario sin Roles',
                            message: 'Actualmente no tienes roles para acceder a esta plataforma, contacta al administrador de la empresa para pedir tus credenciales',
                            type: 'info' 
                          }));
                        }

                        setLocalStorage('authData', createSSOAdapter({...response.data, token: credentialResponse.credential}));
                        navigate("/home");
                      }}
                      onError={() => {
                        dispatch(showToast({ message: 'Hubo un error al iniciar sesión', type: 'error' }))
                      }}
                    />
                  </div>
                </GoogleOAuthProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
