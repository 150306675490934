import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { getAllUsers } from '../../../service/user/user.service';
import { getUserAdapter } from '../adapters/get-user.adapter';

export interface UserInterface {
  id: string;
  name: string;
  image: string;
  email: string;
  accessId: string;
  accessName: string;
}

export class User implements UserInterface{
  id: string = '';
  name: string = '';
  image: string = '';
  email: string = '';
  accessId: string = '';
  accessName: string = '';
}

const ListUser = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<User[]>([]);
  const dispatch = useAppDispatch()

  const loadUsers = async () => {
    dispatch(loaderShow());
    const loadedUsers = await getAllUsers();

    if(loadedUsers.status === ServiceStatusKeys.SUCCESS) {
      setUserList(loadedUsers.data.map((user: any) => getUserAdapter(user)));
    }
    dispatch(loaderDismiss());
  }

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <div className="table-responsive table-scroll">
      <table className='table text-nowrap mb-0'>
        <thead className="table-light text-left table-sticky">
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Rol</th>
            <th className='text-center'>Acciones</th>
          </tr>
        </thead>
        <tbody className='text-left'>
          {
            userList.length > 0 &&
            userList.map(user => (
              <tr key={user.id}>
                <td style={{ verticalAlign: 'middle' }}>{user.name}</td>
                <td style={{ verticalAlign: 'middle' }}>{user.email}</td>
                <td style={{ verticalAlign: 'middle' }}>{user.accessName}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <button className='btn btn-primary btn-sm' onClick={() => navigate(`${user.id}`)}>Asignar</button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default ListUser