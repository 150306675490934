import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderJumbotron from '../../../components/HeaderJumbotron';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { customAlert } from '../../../service/notification/toast.service';
import { getAllRoles } from '../../../service/roles/roles.service';
import { getUserAccessById, getUserById, putUserAccess } from '../../../service/user/user.service';
import { getUserAccessAdapter } from '../adapters/get-user-access.adapter';
import { FormUserAccess } from '../models/form-user-access.model';
import { User } from './ListUser';

export interface UserAccessInterface {
  userId: string;
  userName: string;
  accessId: string;
  accessName: string;
  isActive: boolean;
}

export class UserAccess implements UserAccessInterface {
  userId: string = '';
  userName: string = '';
  accessId: string = '';
  accessName: string = '';
  isActive: boolean = false;
}


const RoleUserAssign = () => {
  const { id } = useParams();
  const [blockField, setBlockField] = useState<boolean>(true);
  const [user, setUser] = useState<User>(new User());
  const [accessUser, setAccessUser] = useState<UserAccess>(new UserAccess());
  const [accessList, setAccessList] = useState<any>([]);
  const [currentAccess, setCurrentAccess] = useState<string>('');
  const dispatch = useAppDispatch();

  const loadUserAccess = async (id: string) => {
    dispatch(loaderShow());
    // FIXME BACKEND al ingresar un id devuelve otro usuario
    const loadedUser = await getUserById(id);
    const loadedUserAccess = await getUserAccessById(id);
    const loadedAccess = await getAllRoles();

    if(
        loadedUserAccess.status === ServiceStatusKeys.SUCCESS &&
        loadedUser.status === ServiceStatusKeys.SUCCESS &&
        loadedAccess.status === ServiceStatusKeys.SUCCESS
    ){
      setUser(loadedUser.data);
      setAccessUser(getUserAccessAdapter(loadedUserAccess.data));
      setAccessList(loadedAccess.data);
      setCurrentAccess(loadedUserAccess.data.accessId);
    }
    dispatch(loaderDismiss());
  }

  const editUserAccess = () => {
    if(id) {
      console.log('User: ', id, 'AccessId: ', currentAccess);

      // FIXME set formAccess Type
      const formAccess: any = {
        accessId: currentAccess,
        isActive: 'activo'
      }

      customAlert('¿Estás seguro de guardar ésta información?').then(response => {
        if(response.isConfirmed) {
          dispatch(loaderShow());
          putUserAccess(id, formAccess).then(async response => {
            dispatch(loaderDismiss());
            if(response.status === ServiceStatusKeys.SUCCESS) {
              await loadUserAccess(id);
              setBlockField(true);
              dispatch(showToast({ message: 'Se ha asignado el nuevo rol', type: 'success' }))
            }
            if(response.status === ServiceStatusKeys.FAIL) {
              dispatch(showToast({ message: 'Hubo un error al asignar el rol', type: 'error' }))
            }
          })
        }
      });
    }
  }

  useEffect(() => {
    if(id) loadUserAccess(id);
  }, []);

  return (
    <>
      <HeaderJumbotron title={'Asignar Rol'} />

      <div className="container-fluid pt-5">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/roles/asignar">
                <span style={{ color: '#FFA645' }}>
                  Dashboard Asignaciones
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Asignar Rol</li>
          </ol>
        </nav>

        <div className="card mb-3" style={{maxWidth: '600px'}}>
          <div className="row g-0">
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img 
                src={user ? user.image : '../../../../public/theme/assets/images/avatar/avatar.jpg'}
                className="rounded-circle"
                referrerPolicy='no-referrer'
                width={100}
                height={100}
              />
            </div>
            <div className="col-md-5">
              <div className="card-body">
                <h4 className="card-title">{ user.name }</h4>
                <p className="card-text">{ accessUser.accessName ? accessUser.accessName : 'Sin Rol' }</p>
                <p className="card-text">{ user.email }</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-body text-end">
                { 
                  accessUser.isActive ?  
                  <span className="badge bg-success">Activo</span> :
                  <span className="badge bg-secondary">Inactivo</span>
                }
                
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <label className='form-label' htmlFor='role-radios'>Seleccione un Rol</label>
            <br />
            <FormControl id='role-radios'
              style={{ width: '100%', height: '200px', overflowY: 'scroll' }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={currentAccess}
                name="radio-buttons-group"
                onChange={(ev) => setCurrentAccess(ev.target.value)}
              >
                {
                  accessList.length > 0 &&
                  accessList.map((access: any) => (
                    <FormControlLabel 
                      disabled={blockField}
                      key={access.id} 
                      value={access.id} 
                      control={<Radio />} 
                      label={access.name} 
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
            <br />
            <hr />

            <div className='d-flex justify-content-end'>
              {
                blockField &&
                <button className='btn btn-primary w-25' onClick={() => setBlockField(false)}>Editar</button>
              }

              {
                !blockField &&
                <button className='btn btn-primary w-25' onClick={editUserAccess}>Asignar</button>
              }
            </div>

          </div>
        </div>


      </div>
    </>
  )
}

export default RoleUserAssign