import React from 'react'

const MaterialTimeCreateForm = () => {
  const changeType = '3bcbb5d1-291c-4585-81af-98ff40a55a3e';

  return (
    <div>
      <div className="card">
        <div className="card-body text-center">
          <div className="spinner-border text-warning mb-3" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <h4>Éste formulario de proyecto está en construcción...</h4>
        </div>
      </div>
      
    </div>
  )
}

export default MaterialTimeCreateForm