import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { customAlert, toast } from '../../../service/notification/toast.service';
import { putRole } from '../../../service/roles/roles.service';
import { checkRolePermissions } from '../../../utilities/check-role-permissions.utility';
import { PERMISSIONS } from '../../../utilities/permissions';
import { VIEW_NAMES } from '../../../utilities/view-names';
import { FormRole } from '../models/form-role.model';

interface IRoleEditForm {
  roleData: FormRole;
}

const RoleEditForm: React.FC<IRoleEditForm> = ({ roleData }) => {
  const { id } = useParams();
  const [blocked, setBlocked] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const roleEditValidationSchema = Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
    isActive: Yup.boolean().typeError('Debe elegir una opcion').required('Campo requerido'),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRole>({
    resolver: yupResolver(roleEditValidationSchema)
  });

  useEffect(() => {
    reset(roleData);
  }, [roleData]);

  const handleEditRole = (data: FormRole) => {
    customAlert('¿Estás seguro de guardar ésta información?').then(response => {
      if(data && response.isConfirmed) {
        dispatch(loaderShow());
        putRole(id || '', data).then(response => {
          dispatch(loaderDismiss());
          if(response.status === ServiceStatusKeys.SUCCESS) {
            navigate('/roles');
            dispatch(showToast({ message: 'Se han editado los roles exitosamente', type: 'success' }))
          }
          if(response.status === ServiceStatusKeys.FAIL) {
            dispatch(showToast({ message: 'Hubo un error al editar los roles', type: 'error' }))
          }
        })
      }
    });
  }
  

  return (
    <form onSubmit={handleSubmit(handleEditRole)}>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Nombre Rol</label>
            <input 
              type="text" 
              { ...register('name') }
              className={`form-control ${
                errors.name ? "is-invalid" : ""
              }`}
              placeholder='Ej. Administrador, Analista, etc.'
              disabled={blocked}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label htmlFor="roleState" className="form-label">Estado</label>
            <select 
              disabled={blocked}
              { ...register('isActive') }
              className={`form-control form-select ${
                errors.isActive ? "is-invalid" : ""
              }`}
            >
              <option value="">Seleccione una opción</option>
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </select>
            <div className="invalid-feedback">{errors.isActive?.message}</div>
          </div>
        </div>
      </div>
      
      {
        checkRolePermissions(VIEW_NAMES.ROLES, PERMISSIONS.EDIT) &&
        <div className="d-flex justify-content-end">
          { 
            blocked && 
            <button type="button" className="btn btn-primary w-25" onClick={() => setBlocked(false)}>Editar</button> 
          }

          { 
            !blocked && 
            <button type="submit" className="btn btn-primary w-25">Guardar</button>
          }
        </div>
      }


    </form>
  )
}

export default RoleEditForm