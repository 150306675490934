import { useState } from 'react';
import CreateKeyOnHandForm from '../CreateKeyOnHandForm';
import IterationAssign from '../IterationAssign';
import ProjectAssign from '../ProjectAssign';

export interface ProjectAssignDataInterface {
  projectId: string;
  accountId: string;
  iterations: number;
}

export class ProjectAssignData {
  projectId: string = '';
  accountId: string = '';
  iterations: number = 0;
}

export interface AssignmentDataInterface {
  projectId: string;
  refreshData: boolean;
}

export class AssignmentData {
  projectId: string = '';
  refreshData: boolean = false;
}

const KeyOnHand = () => {
  const [projectData, setProjectData] = useState<ProjectAssignData | null>(null);
  const [assignmentData, setAssignmentData] = useState<AssignmentData | null>(null);

  const setProjectIdForm = (values: any) => {

    const newProjectData: ProjectAssignData = {
      projectId: values.id,
      accountId: values.accountId,
      iterations: values.iterations
    }

    return setProjectData(newProjectData);
  }

  const handleAssignmentData = (values: any) => {
    const newAssignmentData: AssignmentData = {
      projectId: values.id,
      refreshData: values.regreshData
    }
    return setAssignmentData(newAssignmentData);
  }

  return (
    <>
      {/* Paso 1 */}
      <div className="card mb-5">
        <div className="card-body">
          <h4>Paso 1) Ingresar Datos Cabecera de Proyecto</h4>
          <hr />
          <CreateKeyOnHandForm 
            handleProjectId={setProjectIdForm}
          />
        </div>
      </div>

      {/* Paso 2 */}
      <div className="card mb-5">
        <div className="card-body">
          <h4>Paso 2) Asignación de Colaboradores</h4>
          <hr />
          <ProjectAssign
            projectData={projectData} 
            setAssignmentData = {handleAssignmentData}
          />
        </div>
      </div>

      {/* Paso 3 */}
      <div className="card mb-5">
        <div className="card-body">
          <h4>Paso 3) Ingresar Iteraciones</h4>
          <hr />
          <IterationAssign
            editMode={false}
            assignmentData={assignmentData}
          />
        </div>
      </div>
    </>
  )
}

export default KeyOnHand