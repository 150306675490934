import { Link } from 'react-router-dom';
import CreateCollaboratorForm from './components/CreateCollaboratorForm';
import HeaderJumbotron from '../../components/HeaderJumbotron';
import { DashboardTitles } from "../../config/config";

const CreateCollaboratorPage = () => {
    return (
      <>
        <HeaderJumbotron title={DashboardTitles.CREATE_COLLABORATORS}/>

        <div className="container-fluid pt-5">

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/colaboradores">
                  <span style={{ color: '#FFA645' }}>
                    Dashboard Colaboradores
                  </span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {DashboardTitles.CREATE_COLLABORATORS}
              </li>
            </ol>
          </nav>

          <div className="card mt-5 mb-7">
            <div className="card-body">
              <CreateCollaboratorForm/>
            </div>
          </div>
          
        </div>
      </>
      );
};

export default CreateCollaboratorPage;
