import { User } from "../components/ListUser";

export const getUserAdapter = (endpointUser: any): User => {

  const adaptedUser: User = {
    id: endpointUser.user.id || '',
    name: endpointUser.user.name || '',
    image: endpointUser.user.image || '',
    email: endpointUser.user.email || '',
    accessId: endpointUser.access.id || '',
    accessName: endpointUser.access.name || '',
  }

  return adaptedUser;
}