import { Assignment } from "../models/assignment.model"
import { removeDuplicatesByProperty } from "../../../utilities/removeDuplicates.utility";

export interface AssignArrayInterface {
  name: string;
  firstSurname: string;
  employeeId: string;
  workPosition: string;
  seniority: string;
  amount: number;
  currencyId: string;
  status: boolean;
  iterations: any[];
}

export class AssignArray implements AssignArrayInterface {
  name: string = '';
  firstSurname: string = '';
  employeeId: string = '';
  workPosition: string = '';
  seniority: string = '';
  amount: number = 0;
  status: boolean = false;
  currencyId: string = '';
  iterations: any[] = [];
}

export const createRowArrayAdapter = (endpointArr: any[]): AssignArray[] => {
  const employees = removeDuplicatesByProperty(endpointArr.map(ep => ({ employeeId: ep.employee?.id})), 'employeeId');

  const mappedAdaptedAssignments: AssignArray[] = employees.map(employee => {
    const filtered = endpointArr.filter(ep => ep.employee?.id === employee.employeeId);
    return {
      ...employee,
      name: filtered[0].employee?.firstName,
      firstSurname: filtered[0].employee?.firstSurname,
      workPosition: filtered[0].workPosition?.id,
      seniority: filtered[0].seniority?.id,
      amount: filtered[0].amount,
      status: filtered[0].active,
      currencyId: filtered[0].currency?.id,
      iterations: filtered.map(fp => ({
        assignmentId: fp.id,
        id: fp.iteration,
        value: fp.asignationPercentage
      })),
    }
  });

  return mappedAdaptedAssignments;
}