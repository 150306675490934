import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { FormRolePermission } from "../../pages/roles/models/form-role-permission.model";
import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { API_URL } from "../api-url";

export const postRolesPermissions = async (data: FormRolePermission): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.post(`${API_URL}capcalc/ViewPermissionAccess`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const getRolesPermissionsById = async (id: string): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/ViewPermissionAccess/${id}`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const putRolesPermissions = async (id: string, data: FormRolePermission): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.put(`${API_URL}capcalc/ViewPermissionAccess/Access/${id}`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}