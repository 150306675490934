import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LoaderState {
  isActive: boolean;
}

const initialState: LoaderState = {
  isActive: false
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    loaderShow: (state) => { state.isActive = true; },
    loaderDismiss: (state) => { state.isActive = false; },
  },
})

export const { loaderShow, loaderDismiss } = loaderSlice.actions
export const selectCount = (state: RootState) => state.loader;

export default loaderSlice.reducer