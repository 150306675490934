import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { checkRolePermissions } from '../../../utilities/check-role-permissions.utility';

interface IRoleProtected {
  viewName: string;
  permission: string;
  children: any;
}

const RoleProtected: FC<IRoleProtected> = ({ viewName, permission , children }) => {
  if (!checkRolePermissions(viewName, permission)) {
    return <Navigate to="/home" replace />
  }
  return children
}

export default RoleProtected