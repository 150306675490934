import { Outlet } from "react-router-dom";
import Head from "./Head";
import Header from "./Header";
import NavbarVertical from "./NavbarVertical";
import Scripts from "./Scripts";

export const Layout = () => {
  return (
    <>
      <Head />
      <div className="bg-light">
        <div id="db-wrapper">
          <NavbarVertical />
          <div id="page-content">
            <Header />
            {/* <div className="bg-primary pt-10 pb-21"></div> */}
            <Outlet/>
          </div>
        </div>
        <Scripts />
      </div>
    </>
  );
};
