import MaterialTimeCreateForm from '../MaterialTimeCreateForm'

const MaterialTime = () => {
  return (
    <>
      {/* <div>MaterialTime Paso 1</div> */}
      <MaterialTimeCreateForm/>
    </>
  )
}

export default MaterialTime