import { CSSProperties, useState } from "react";
import { Link } from "react-router-dom";
import { checkRolePermissions } from "../utilities/check-role-permissions.utility";
import { PERMISSIONS } from "../utilities/permissions";
import { VIEW_NAMES } from "../utilities/view-names";

const collapseHeaderBtn: CSSProperties = {
  width: '100%',
  display: 'flex',
  gap: '15px',
  padding: '10px 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 'none',
  background: 'none',
  color: '#fff'
}

const NavbarVertical = () => {

  const [navItems, setNavItems] = useState<any[]>([
    {
      id: 'collapsable1',
      title: 'Proyectos',
      view: VIEW_NAMES.PROJECTS,
      condition: PERMISSIONS.READ,
      icon: <i  style={{ fontSize: '22px' }}className="bi bi-stack"></i>,
      subItems: [
        {
          id: 'subItem1',
          url: '/proyectos',
          view: VIEW_NAMES.PROJECTS,
          condition: PERMISSIONS.READ,
          subTitle: 'Dashboard Proyectos'
        },
        {
          id: 'subItem2',
          url: '/proyectos/nuevo',
          view: VIEW_NAMES.PROJECTS,
          condition: PERMISSIONS.WRITE,
          subTitle: 'Crear Proyecto'
        }
      ]
    },
    {
      id: 'collapsable2',
      title: 'Colaboradores',
      view: VIEW_NAMES.COLLABORATORS,
      condition: PERMISSIONS.READ,
      icon: <i style={{ fontSize: '22px' }} className="bi bi-people-fill"></i>,
      subItems: [
        {
          id: 'subItem1',
          url: '/colaboradores',
          view: VIEW_NAMES.COLLABORATORS,
          condition: PERMISSIONS.READ,
          subTitle: 'Dashboard Colaboradores'
        },
        {
          id: 'subItem2',
          url: '/colaboradores/crear',
          view: VIEW_NAMES.COLLABORATORS,
          condition: PERMISSIONS.WRITE,
          subTitle: 'Crear Colaborador'
        }
      ]
    },    
    {
      id: 'collapsable3',
      title: 'Roles',
      view: VIEW_NAMES.ROLES,
      condition: PERMISSIONS.READ,
      icon: <i style={{ fontSize: '22px' }} className="bi bi-person-video2"></i>,
      subItems: [
        {
          id: 'subItem1',
          url: '/roles',
          view: VIEW_NAMES.ROLES,
          condition: PERMISSIONS.READ,
          subTitle: 'Dashboard Roles'
        },
        {
          id: 'subItem2',
          url: '/roles/crear',
          view: VIEW_NAMES.ROLES,
          condition: PERMISSIONS.WRITE,
          subTitle: 'Crear Rol'
        },
        {
          id: 'subItem3',
          url: '/roles/asignar',
          view: VIEW_NAMES.ROLES,
          condition: PERMISSIONS.READ,
          subTitle: 'Asignar Rol'
        },
      ]
    }
  ]);



  return (
    <nav className="navbar-vertical navbar">
      <div className="nav-scroller">

          <div className="navbar-brand d-flex justify-content-center mt-5">
              <img
                alt="Logo Xintec"
                src="/theme/assets/images/brand/logo/xintec-white-logo-alta.png"
                className="img-fluid"
              />
          </div>

          <Link  to={'/home'}>
            <button 
              style={collapseHeaderBtn} 
              type="button" >
              <div>
              <i className="bi bi-house-fill" style={{ fontSize: '22px' }}></i>
                <span className="ms-2">Página Principal</span>
              </div>
            </button>
          </Link>

          {
            navItems.length > 0 &&
            navItems.map((navItem: any) => (
              <div key={navItem.id}>
                {
                  checkRolePermissions(navItem.view,navItem.condition) &&
                  <div key={navItem.id}>
                    <button 
                      style={collapseHeaderBtn} 
                      type="button" data-bs-toggle="collapse"
                      data-bs-target={ '#'+navItem.id } aria-expanded="false" 
                      aria-controls={ navItem.id }>
                      <div>
                        { navItem.icon }
                        <span className="ms-2">{ navItem.title }</span>
                      </div>
                      <i className="bi bi-chevron-down"></i>
                    </button>
                    <div className="collapse" id={ navItem.id }>
                      <div style={{ padding: '20px 35px', display: 'flex', flexDirection: 'column', gap:'15px' }}>
                        {
                          navItem.subItems.length > 0 &&
                          navItem.subItems.map((subItem: any) => (
                            <div key={subItem.id}>
                              {
                                checkRolePermissions(subItem.view, subItem.condition) &&
                                <li>
                                  <Link  to={subItem.url}>
                                    <span style={{ color: '#fff'}}>
                                      {subItem.subTitle}
                                    </span>
                                  </Link>
                                </li>
                              }
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            ))
          }
      </div>
    </nav>
  );
};

export default NavbarVertical;
