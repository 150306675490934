import * as Yup from 'yup';
import { calTodayMaxAge } from './calc-max-age.utility';

const MAX_AGE = calTodayMaxAge();
const MAX_DATE = "2100-12-31";

export const validationSchema = Yup.object().shape({ 
  CIType: Yup.string().required("Seleccione una opción"),
  CI: Yup.string().when("CIType", (CIType, schema) => {
    if (CIType === "run") {
      return schema
        .min(9, "El RUN debe tener un mínimo de 9 caracteres")
        .max(10, "El RUN debe tener un máximo de 10 caracteres")
        .matches(
          /(\d{7}|\d{8})\-(\d{1}|k|K)/,
          "El formato de RUT no es válido"
        )
        .required("Campo requerido");
    }
    if (CIType === "dni") {
      return schema
        .min(9, "El DNI debe tener un mínimo de 9 caracteres")
        .max(9, "El DNI debe tener un máximo de 9 caracteres")
        .matches(/^\d{8}[a-zA-Z]$/, "El formato de DNI no es válido")
        .required("Campo requerido");
    }
    if (CIType === "passport") {
      return schema
        .min(9, "El Pasaporte debe tener un mínimo de 9 caracteres")
        .max(9, "El Pasaporte debe tener un máximo de 9 caracteres")
        .matches(
          /^([a-zA-z]{1}\d{8}|[a-zA-Z]{2}\d{7}|[a-zA-Z]{3}\d{6})$/,
          "El formato de Pasaporte no es válido"
        )
        .required("Campo requerido");
    }
  }),
  firstName: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  secondName: Yup.string()
  .nullable()
  .transform((curr, orig) => orig === '' ? null : curr)
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
    "Solo se permiten letras para este campo"
  ),
  firstSurname: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  secondSurname: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  nationality: Yup.string().required("Campo requerido"),
  gender: Yup.string().required("Seleccione una opción"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._]*@xintec.cl$/,
      "El formato de email no es válido"
    )
    .required("Campo requerido"),
  phoneNumber: Yup.string()
    .min(10, "El teléfono debe tener un mínimo de 10 caracteres")
    .max(13, "El teléfono debe tener un máximo de 13 caracteres")
    .matches(/\+(\d{12}|\d{11}|\d{10})/, "El formato de número no es válido")
    .required("Campo requerido"),
  emergencyPhoneNumber: Yup.string()
    .min(10, "El teléfono debe tener un mínimo de 10 caracteres")
    .max(13, "El teléfono debe tener un máximo de 13 caracteres")
    .matches(/\+(\d{12}|\d{11}|\d{10})/, "El formato de número no es válido")
    .required("Campo requerido"),
  emergencyPhoneName: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  birthDate: Yup.date()
    .max(new Date(MAX_AGE), "El usuario debe ser mayor a 18 años")
    .typeError("Debe especificar una fecha de nacimiento válida")
    .required("Campo requerido"),
  entryDate: Yup.date()
    .max(new Date(MAX_DATE), "Debe especificar una fecha máxima válida")
    .typeError("Debe especificar una fecha válida")
    .required("Campo requerido"),
  departureDate: Yup.date()
    .transform((curr, orig) => (orig === 'Invalid date' || orig === '') ? null : curr)
    .nullable()
    .max(new Date(MAX_DATE), "Debe especificar una fecha máxima válida"),
  country: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  address: Yup.string().required("Campo requerido"),
  commune: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
  region: Yup.string()
    .matches(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      "Solo se permiten letras para este campo"
    )
    .required("Campo requerido"),
});