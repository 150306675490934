import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { FormProjectUpdate } from "../../pages/projects/models/form-project-update.model";
import { FormProject } from "../../pages/projects/models/form-project.model";
import { API_URL } from "../api-url";

export const postProject = async (
  data: FormProject
): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.post(`${API_URL}capcalc/project/projects`, data),
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(error.response, ServiceStatusKeys.FAIL);
  }
};

export const getProjects = async (): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/project/projects`),
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(error.response, ServiceStatusKeys.FAIL);
  }
};

export const getProjectById = async (id: string): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/project/projects/${id}`),
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(error.response, ServiceStatusKeys.FAIL);
  }
};

export const putProject = async (
  data: FormProjectUpdate,
  id: string
): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.put(`${API_URL}capcalc/project/projects/${id}`, data),
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(error.response, ServiceStatusKeys.FAIL);
  }
};
