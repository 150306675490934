import useScript from "../hooks/useScripts";

function Scripts() {
  useScript("/theme/assets/providers/jquery-3.6.0.min.js");
  useScript("/theme/assets/providers/bootstrap.bundle.min.js");
  useScript("/theme/assets/providers/jquery.slimscroll.min.js");
  useScript("/theme/assets/providers/feather.min.js");
  useScript("/theme/assets/providers/apexcharts.min.js");
  useScript("/theme/assets/js/main.js");
  useScript("/theme/assets/js/feather.js");
  useScript("/theme/assets/js/sidebarMenu.js");

  return <div className="Scripts"></div>;
}

export default Scripts;
