import { FormProjectUpdate } from "../models/form-project-update.model";

export const createFormProjectUpdateAdapter = (endpointData: any): FormProjectUpdate => {
  const adaptedProject: FormProjectUpdate = {
    projectName: endpointData.name,
    projectStartDate: endpointData.startDate,
    projectEndDate: endpointData.endDate,
    projectAmount: endpointData.amount,
    accountId: endpointData.accountId,
    projectContactName: endpointData.contactName,
    projectTotalHours: endpointData.totalHours,
    projectTotalDays: endpointData.totalDays,
    projectIteration: endpointData.iteration,
    projectPlanning: endpointData.planning,
    projectReview: endpointData.review,
    projectDaily: endpointData.daily,
    projectDaysIteration: endpointData.daysIteration,
    projectIterationEmployee: endpointData.iterationEmployee,
    projectNumberEmployee: endpointData.numberEmployee,
    projectTotalIteration: endpointData.totalIteration,
    projectCurrencyId: endpointData.currencyId,
    projectTypeId: endpointData.typeId,
    projectStatus: endpointData.status,
    description: endpointData.description,
    serviceType: endpointData.serviceType,
  }

  return adaptedProject;
}