import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { getAllRoles } from '../../../service/roles/roles.service';
import { Role } from '../models/role.model';

const ListRoles = () => {
  const dispatch = useAppDispatch();
  const [accessList, setAccessList] = useState<Role[]>([]);

  const loadRoles = async () => {
    dispatch(loaderShow());
    const loadedRoles = await getAllRoles();
    if(loadedRoles.status === ServiceStatusKeys.SUCCESS) {
      setAccessList(loadedRoles.data);
    }
    dispatch(loaderDismiss());
  }

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <div className="table-responsive table-scroll">
      <table className='table text-nowrap mb-0'>
        <thead className="table-light text-left table-sticky">
          <tr>
            <th>Nombre</th>
            <th>Estado</th>
            <th className='text-center'>Acciones</th>
          </tr>
        </thead>
        <tbody className='text-left'>
          {
            accessList.length > 0 &&
            accessList.map(access => (
              <tr key={access.id}>
                <td>{access.name}</td>
                <td>
                  {
                    (access.isActive) ? 
                    <span className="badge bg-success">Activo</span> :
                    <span className="badge bg-secondary">Inactivo</span>
                  }
                </td>
                <td className='text-center'>
                  <Link to={`editar/${access.id}`}>
                    <button className='btn btn-primary btn-sm'>Ver</button>
                  </Link>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default ListRoles;