import { Navigate, Outlet } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/hooks";
import { showToast } from "../../../redux/slices/toastSlice";

export const RouteGuard = () => {
  const dispatch = useAppDispatch();

  if (!localStorage.getItem("authData")) {
    dispatch(showToast({ 
      title:'La sesión ha finalizado',
      message: 'Vuelve a iniciar sesión desde la plataforma',
      type: 'info'
    }));
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export const RouteGuardLogin = () => {
  if (localStorage.getItem("authData")) {
    return <Navigate to="/home" />;
  }
  return <Outlet />;
};
