
import { getLocalStorage } from "../service/local-storage/storage.service";
import { checkUserAuthentication } from "./check-user-authentication.utility";

export const checkRolePermissions = (viewName: string, permission: string) => {

  if(!viewName && !permission) return 0;

  if(!checkUserAuthentication()) return false;

  const currentUser = getLocalStorage('authData');


  // TODO PARCHE
  const permissionArray = currentUser.role.permissions[viewName];


  if(!permissionArray) return false;

  const mappedPermissions = permissionArray.filter((perm: any) => perm.isActive)
  .map((perm: any) => perm.name);

  if(mappedPermissions.includes(permission)) return true;
  return false;
}