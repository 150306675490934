import { EmployeeInterface } from "./employee.interface";

export class Employee implements EmployeeInterface {
  id: string = '';
  CI: string = '';
  CIType: string = '';
  firstName: string = '';
  secondName: string = '';
  thirdName: string = '';
  firstSurname: string = '';
  secondSurname: string = '';
  nationality: string = '';
  gender: string = '';
  email: string = '';
  phoneNumber: string = '';
  emergencyPhoneNumber: string = '';
  emergencyPhoneName: string = '';
  birthDate: string = '';
  entryDate: string = '';
  departureDate: string = '';
  country: string = '';
  address: string = '';
  commune: string = '';
  region: string = '';
  availableForTravel: boolean = false;
  activeEmployee: boolean = false;
}