import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { FormUserAccess } from "../../pages/roles/models/form-user-access.model";
import { API_URL } from "../api-url";

export const getAllUsers = async (): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}/capcalc/accessuser/allaccessusers`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}


export const getUserById = async (id: string): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}/capcalc/user/${id}`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const getUserAccessById = async (id: string): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}/capcalc/accessuser/access/${id}`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const putUserAccess = async (id: string, data: FormUserAccess): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.put(`${API_URL}/capcalc/accessuser/${id}`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}