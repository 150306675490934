import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import { Account } from '../../../interfaces/account/account.model';
import { Currency } from '../../../interfaces/currency/currency.model';
import { ProjectType } from '../../../interfaces/project-types/project-type.model';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { getAllAccounts } from '../../../service/accounts/account.service';
import { getAllProjectTypes } from '../../../service/accounts/project-types.service';
import { getAllCurrencies } from '../../../service/currency/currency.service';
import { customAlert, toast } from '../../../service/notification/toast.service';
import { getProjectById, putProject } from '../../../service/project/project.service';
import { getAllServicesTypes } from '../../../service/services-types/services-types.service';
import { checkRolePermissions } from '../../../utilities/check-role-permissions.utility';
import { Formulas } from '../../../utilities/formulas.utility';
import { PERMISSIONS } from '../../../utilities/permissions';
import { VIEW_NAMES } from '../../../utilities/view-names';
import { createProjectAdapter } from '../adapters/create-project.adapter';
import { createFormProjectUpdateAdapter } from '../adapters/form-project-update.adapter';
import { FormProjectUpdate } from '../models/form-project-update.model';
import { Project } from '../models/project.model';
import { projectValidation } from '../utilities/project-validation.utility';

interface IUpdateProject {
  blocked: boolean;
  setBlocked: Function;
  setProjectUpdateData: Function;
}

const UpdateProject: FC<IUpdateProject> = ({ setProjectUpdateData, blocked, setBlocked }) => {
  const { id } = useParams();
  const [projectData, setProjectData] = useState<Project | null>(null);  
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [serviceTypes, setServiceTypes] = useState<any[]>([]);
  const [calculatedFinalSprint, setCalculatedFinalSprint] = useState<number>(0);
  const formulas = new Formulas();
  const dispatch = useAppDispatch();

  const {
    register,
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, dirtyFields }
  } = useForm<Project>({
    resolver: yupResolver(projectValidation)
  });

  const startDateField = useController({ name: 'startDate', control });
  const totalDaysField = useController({ name: 'totalDays', control });
  const totalHoursField = useController({ name: 'totalHours', control });
  const sprintField = useController({ name: 'iteration', control });
  const planningField = useController({ name: 'planning', control });
  const reviewField = useController({ name: 'review', control });
  const dailyField = useController({ name: 'daily', control });
  const collaboratorField = useController({ name: 'numberEmployee', control });
  const finalSprintField = useController({ name: 'totalIteration', control });

  const handleTotalDays = (ev: any) => {
    totalDaysField.field.onChange(ev.target.value);
    return setValue('totalHours', formulas.totalHours(ev.target.value || 0));
  }

  const handleTotalHours = (ev: any) => {
    totalHoursField.field.onChange(ev.target.value);
    return setValue('totalDays', formulas.daysPerHour(ev.target.value || 0));
  }

  const editProject = (data: Project) => {
    if(projectData) {
      const adaptedFormProject: FormProjectUpdate = createFormProjectUpdateAdapter(data);

      customAlert('¿Estás seguro de guardar ésta información?').then(res => {
        if(res.isConfirmed) {
          dispatch(loaderShow());
          putProject(adaptedFormProject, projectData.id).then(response => {
            dispatch(loaderDismiss());
            if(response.status === ServiceStatusKeys.SUCCESS) {
              setBlocked(true);
              dispatch(showToast({ message: 'Se ha editado el proyecto exitosamente', type: 'success' }));
            }
            if(response.status === ServiceStatusKeys.FAIL) {
              dispatch(showToast({ message: 'Hubo un error al editar el proyecto', type: 'error' }));
            }
          })
        }
      })
    }
  }

  const loadProjectData = async (id: string) => {

    dispatch(loaderShow());
    const project = await getProjectById(id);
    const adaptedProject = createProjectAdapter(project.data);
    const accountId = project.data.account.id;
    const projectTypes = await getAllProjectTypes();
    const accounts = await getAllAccounts();
    const currencies = await getAllCurrencies(accountId);
    const serviceTypes = await getAllServicesTypes();
    dispatch(loaderDismiss());

    if(project && accountId && projectTypes && accounts && currencies && serviceTypes) {
      setProjectData(adaptedProject);
      setProjectTypes(projectTypes.data);
      setAccounts(accounts.data);
      setCurrencies(currencies.data);
      setServiceTypes(serviceTypes.data);
    }

    reset(adaptedProject);
    setProjectUpdateData({ projectId: adaptedProject.id, accountId: accountId, iterations: Math.ceil(adaptedProject.totalIteration) })
    setCalculatedFinalSprint(adaptedProject.totalIteration);

    return;
  }

  useEffect(() => {
    if(id) {
      loadProjectData(id);
    }
  }, []);

  useEffect(() => {
    if(
      sprintField.field.value &&
      reviewField.field.value &&
      planningField.field.value &&
      dailyField.field.value &&
      collaboratorField.field.value
    ) {
      const ceremonyHours = formulas.ceremonyHours(
        Number(dailyField.field.value),
        Number(sprintField.field.value),
        Number(planningField.field.value),
        Number(reviewField.field.value),
      );
      const effectiveHours = formulas.effectiveHours(ceremonyHours, sprintField.field.value);
      const effectiveDays = formulas.effectiveDays(effectiveHours);
      const sprints = formulas.sprintCalc(totalDaysField.field.value, effectiveDays);
      if(dirtyFields.numberEmployee) {
        const finalSprint = formulas.finalSprintCalc(getValues('iterationEmployee'), collaboratorField.field.value);
        setValue('totalIteration', finalSprint);
      }
      setValue('daysIteration', effectiveDays);
      setValue('iterationEmployee', sprints);
    }
  }, [
    sprintField.field.value,
    reviewField.field.value,
    planningField.field.value,
    dailyField.field.value,
    totalDaysField.field.value,
    collaboratorField.field.value
  ]);

  return (
    <form onSubmit={handleSubmit(editProject)}>

      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Tipo de proyecto</label>
            <select
              disabled={true}
              { ...register('typeId') }
              className={`form-control form-select ${
                errors.typeId ? "is-invalid" : ""
              }`}
            >
              <option value="">Seleccione el tipo proyecto</option>
              {
                projectTypes.length > 0 &&
                projectTypes.map((type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                )))
              }
            </select>
            <div className="invalid-feedback">{errors.typeId?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Estado</label>
            <select
              disabled={blocked}
              { ...register('status') }
              className={`form-control form-select ${
                errors.status ? "is-invalid" : ""
              }`}
            >
              <option value="">Seleccione el estado</option>
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
            <div className="invalid-feedback">{errors.status?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Nombre</label>
            <input 
              disabled={blocked}
              type="text" 
              { ...register('name') }
              className={`form-control ${
                errors.name ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Contacto</label>
            <input 
              disabled={blocked}
              type="text" 
              { ...register('contactName') }
              className={`form-control ${
                errors.contactName ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.contactName?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Cliente</label>
            <select
              disabled={blocked}
              { ...register('accountId') }
              className={`form-control form-select ${
                errors.accountId ? "is-invalid" : ""
              }`}
              // onChange={(ev) => setCurrentAccount(ev.target.value)}
            >
              <option value="">Seleccione el cliente</option>
              {
                accounts.length > 0 &&
                accounts.map(account => (
                  <option key={account.id} value={account.id}>{account.name}</option>
                ))
              }
            </select>
            <div className="invalid-feedback">{errors.accountId?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Fecha Inicio</label>
            <input 
              disabled={blocked}
              type="date"
              { ...register('startDate') }
              className={`form-control ${
                errors.startDate ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.startDate?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Fecha Finalización</label>
            <input 
              disabled={blocked}
              type="date"
              { ...register('endDate') }
              className={`form-control ${
                errors.endDate ? "is-invalid" : ""
              }`}
              min={startDateField.field.value}
            />
            <div className="invalid-feedback">{errors.endDate?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Dias Totales</label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={totalDaysField.field.value}
              onChange={handleTotalDays}
              className={`form-control ${
                errors.totalDays ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.totalDays?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              Horas Totales
              <i
                className="bi bi-info-circle-fill mx-2"
                data-bs-toggle="tooltip"
                title="Duración máxima del proyecto en Horas"
                style={{ cursor: 'pointer', color: 'gray' }}
              ></i>
            </label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={totalHoursField.field.value}
              onChange={handleTotalHours}
              className={`form-control ${
                errors.totalHours ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.totalHours?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              Sprint
              <i
                className="bi bi-info-circle-fill mx-2"
                data-bs-toggle="tooltip"
                title="Duración máxima de una iteración, Ej: 2 semanas"
                style={{ cursor: 'pointer', color: 'gray' }}
              ></i>
            </label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={sprintField.field.value}
              onChange={(ev) => sprintField.field.onChange(ev.target.value)}
              className={`form-control ${
                errors.iteration ? "is-invalid" : ""
              }`}
            />
            <span className='form-text'>Ej. 2 (semanas)</span>
            <div className="invalid-feedback">{errors.iteration?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              Planning
              <i
                className="bi bi-info-circle-fill mx-2"
                data-bs-toggle="tooltip"
                title="Total de horas por sprint para crear una planning"
                style={{ cursor: 'pointer', color: 'gray' }}
              ></i>
            </label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={planningField.field.value}
              onChange={(ev) => planningField.field.onChange(ev.target.value)}
              className={`form-control ${
                errors.planning ? "is-invalid" : ""
              }`}
            />
            <span className='form-text'>Ej: 2 (horas)</span>
            <div className="invalid-feedback">{errors.planning?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              Review
              <i
                className="bi bi-info-circle-fill mx-2"
                data-bs-toggle="tooltip"
                title="Total de horas por sprint para realizar una review"
                style={{ cursor: 'pointer', color: 'gray' }}
              ></i>
            </label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={reviewField.field.value}
              onChange={(ev) => reviewField.field.onChange(ev.target.value)}
              className={`form-control ${
                errors.review ? "is-invalid" : ""
              }`}
            />
            <span className='form-text'>Ej: 1 (hora)</span>
            <div className="invalid-feedback">{errors.review?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              Daily
              <i
                className="bi bi-info-circle-fill mx-2"
                data-bs-toggle="tooltip"
                title="Total de horas por sprint para realizar una daily"
                style={{ cursor: 'pointer', color: 'gray' }}
              ></i>
            </label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={dailyField.field.value}
              onChange={(ev) => dailyField.field.onChange(ev.target.value)}
              className={`form-control ${
                errors.daily ? "is-invalid" : ""
              }`}
            />
            <span className='form-text'>Ej: 0.5 (hora)</span>
            <div className="invalid-feedback">{errors.daily?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Dias Efectivos Sprint</label>
            <input 
              disabled={true}
              type="number"
              step={.01}
              { ...register('daysIteration') }
              className={`form-control ${
                errors.daysIteration ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.daysIteration?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Sprints</label>
            <input 
              disabled={true}
              type="number"
              step={.01}
              { ...register('iterationEmployee') }
              className={`form-control ${
                errors.iterationEmployee ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.iterationEmployee?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">
              Colaboradores
              <i
                className="bi bi-info-circle-fill mx-2"
                data-bs-toggle="tooltip"
                title="Total de colaboradores del proyecto"
                style={{ cursor: 'pointer', color: 'gray' }}
              ></i>
            </label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              value={collaboratorField.field.value}
              onChange={(ev) => collaboratorField.field.onChange(ev.target.valueAsNumber)}
              className={`form-control ${
                errors.numberEmployee ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.numberEmployee?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Sprint Final</label>
            <input 
              disabled={blocked}
              type="number"
              step={.01}
              // { ...register('totalIteration') }
              value={finalSprintField.field.value}
              onChange={(ev) => finalSprintField.field.onChange(ev.target.valueAsNumber)}
              className={`form-control ${
                errors.totalIteration ? "is-invalid" : ""
              }`}
            />
            <span className='form-text'>Campo calculado: {calculatedFinalSprint}</span>
            <div className="invalid-feedback">{errors.totalIteration?.message}</div>
          </div>
        </div>
        <div className="col">
          <label className='form-label'>Tarifa</label>
          <div className="input-group mb-3">
            <select
              disabled={blocked}
              {...register("currencyId")}
              className={`input-group-text ${
                errors.currencyId ? "is-invalid" : ""
              }`}
              onChange={(ev) =>
                setValue("currencyId", ev.target.value, {shouldValidate: true})
              }
              defaultValue={'opt'}
            >
              <option value="opt" disabled>
                🪙
              </option>
              { 
                currencies.length > 0 &&
                currencies.map((currency) => 
                  (<option key={currency.id} value={currency.id}>{currency.symbol}</option>)
                )
              }
            </select>
            <input
              disabled={blocked}
              placeholder="50000"
              type="number"
              {...register("amount")}
              className={`radio-right-input form-control ${
                errors.amount ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.amount?.message}
            </div>
            <div className="invalid-feedback">
              {errors.currencyId?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Tipo de Servicio</label>
            <select
              {...register("serviceType")}
              className={`form-control form-select ${
                errors.serviceType ? "is-invalid" : ""
              }`}
              disabled={blocked}
            >
              <option value="">Indique el tipo servicio</option>
              {
                serviceTypes.length > 0 &&
                serviceTypes.map((serviceType) => (
                  <option key={serviceType.id} value={serviceType.id}>
                    {serviceType.name}
                  </option>
                ))
              }
            </select>
            <div className="invalid-feedback">{errors.serviceType?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Descripción</label>
            <textarea 
              style={{ resize: 'none' }}
              {...register('description')}
              disabled={blocked}
              className={`form-control ${
                errors.description ? "is-invalid" : ""
              }`}
              cols={30} rows={2}
              maxLength={200}
              placeholder="El proyecto...">
            </textarea>
            <span className="form-text">Máximo 200 caracteres</span>
            <div className="invalid-feedback">
              {errors.description?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />
      
      {
        checkRolePermissions(VIEW_NAMES.PROJECTS, PERMISSIONS.EDIT) &&
        <div className="d-flex justify-content-end">
          {
            (blocked) &&
            <button type='button' className='btn btn-primary w-25' onClick={() => setBlocked(false)}>Editar</button>
          }
          {
            (!blocked) &&
            <button type='submit' className='btn btn-primary w-25'>Guardar</button>
          }
        </div>
      }

    </form>
  )
}

export default UpdateProject