export const removeDuplicates = (arr: any[]): any[] => {
  return arr.filter((item,index) => arr.indexOf(item) === index);
}

export const removeDuplicatesByProperty = (arr: Array<any>, prop: any): any[] => {
  return arr.reduce((accumulator, current) => {
    if (!accumulator.find((item: any) => item[prop] === current[prop])) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
}