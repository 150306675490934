import React from 'react'
import { Link } from 'react-router-dom'
import HeaderJumbotron from '../../components/HeaderJumbotron'
import ListRoles from './components/ListRoles'
import { checkRolePermissions } from '../../utilities/check-role-permissions.utility'
import { VIEW_NAMES } from '../../utilities/view-names'
import { PERMISSIONS } from '../../utilities/permissions'

const ListRolesPage = () => {
  return (
    <>
      <HeaderJumbotron title='Dashboard de Roles' />

      <div className="container-fluid pt-5">
        
        {
          checkRolePermissions(VIEW_NAMES.ROLES, PERMISSIONS.WRITE) &&
          <div className='d-md-flex justify-content-md-end mb-5'>
            <Link to="crear">
              <button className='btn btn-primary'>
                Crear Rol
              </button>
            </Link>
          </div>
        }
        
        <div className="card">
          <div className="card-body">
            <ListRoles/>
          </div>
        </div>

      </div>
    </>
  )
}

export default ListRolesPage;