import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import {
  DashboardButtons
} from "../../../config/config";
import { useAppDispatch } from "../../../hooks/hooks";
import { ServiceStatusKeys } from "../../../interfaces/service-response/service-status-keys";
import { loaderDismiss, loaderShow } from "../../../redux/slices/loaderSlice";
import { getProjects } from "../../../service/project/project.service";
import { currencyPipe } from "../../../utilities/currency-pipe.utility";
import { createProjectAdapter } from "../adapters/create-project.adapter";
import { Project } from "../models/project.model";

const ListProjects = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);

  const loadProjects = async () => {
    dispatch(loaderShow());
    const projectsList = await getProjects();

    if(projectsList.status === ServiceStatusKeys.SUCCESS) {
      const adaptedProjects = projectsList.data.map((project: any) => createProjectAdapter(project))
      setProjects(adaptedProjects);
    }

    return dispatch(loaderDismiss());
  };

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <div className="table-responsive table-scroll">
      <table className="table text-nowrap mb-0">
        <thead className="table-light table-sticky">
          <tr>
            <th>Nombre</th>
            <th>Contacto</th>
            <th>Cliente</th>
            <th>Fecha Inicio</th>
            <th>Fecha Finalización</th>
            <th>Tarifa Proyecto</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {projects.length > 0 &&
            projects.map((project, index) => {
              return (
                <tr key={project.id}>
                  <td>{project.name}</td>
                  <td>{project.contactName}</td>
                  <td>{project.accountName}</td>
                  <td>
                    <Moment format="DD/MM/YYYY">
                      {project.startDate}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD/MM/YYYY">
                      {project.endDate}
                    </Moment>
                  </td>
                  <td>{project.currencySymbol} {currencyPipe(project.amount)}</td>
                  <td className="text-center">
                    <button
                      onClick={() => navigate(`/proyectos/${project.id}`)}
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      {DashboardButtons.SEE}
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ListProjects;
