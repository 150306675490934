import "bootstrap-icons/font/bootstrap-icons.css";
import "prismjs/themes/prism-okaidia.min.css";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import LoginPage from "./pages/login/LoginPage";
import RoleProtected from "./pages/login/components/RoleProtected";
import {
  RouteGuard,
  RouteGuardLogin
} from "./pages/login/components/RouteGuard";
import ListCollaboratorPage from "./pages/collaborators/ListCollaboratorPage";
import CreateCollaboratorPage from "./pages/collaborators/CreateCollaboratorPage";
import UpdateCollaboratorsPage from "./pages/collaborators/UpdateCollaboratorsPage";
import HomePage from "./pages/home/HomePage";
import CreateProjectPage from "./pages/projects/CreateProjectPage";
import ListProjectPage from "./pages/projects/ListProjectPage";
import UpdateProjectPage from "./pages/projects/UpdateProjectPage";
import CreateRolePage from "./pages/roles/CreateRolePage";
import ListRolesPage from "./pages/roles/ListRolesPage";
import UpdateRolePage from "./pages/roles/UpdateRolePage";
import "./theme/css/theme.css";
import { VIEW_NAMES } from "./utilities/view-names";
import { PERMISSIONS } from "./utilities/permissions";
import AssignationRolePage from "./pages/roles/AssignationRolePage";
import RoleUserAssign from "./pages/roles/components/RoleUserAssign";

function App() {
  return (
    <Routes>

      <Route element={<RouteGuardLogin />}>
        <Route path="/" element={<LoginPage />}>
          {""}
        </Route>
      </Route>

      <Route element={<RouteGuard />}>
        <Route path="home" element={<Layout />}>
          <Route index element={<HomePage />}/>
        </Route>
      </Route>

      <Route element={<RouteGuard />}>
        <Route path="proyectos" element={<Layout />}>
          <Route index element={
            <RoleProtected viewName={VIEW_NAMES.PROJECTS} permission={PERMISSIONS.READ}>
              <ListProjectPage />
            </RoleProtected>
          }/>
          <Route path=":id" element={
            <RoleProtected viewName={VIEW_NAMES.PROJECTS} permission={PERMISSIONS.READ}>
              <UpdateProjectPage />
            </RoleProtected>
          }/>
          <Route path="nuevo" element={
            <RoleProtected viewName={VIEW_NAMES.PROJECTS} permission={PERMISSIONS.WRITE}>
              <CreateProjectPage />
            </RoleProtected>
          }/>
        </Route>
      </Route>

      <Route element={<RouteGuard />}>
        <Route path="colaboradores" element={<Layout />}>
          <Route index element={
            <RoleProtected viewName={VIEW_NAMES.COLLABORATORS} permission={PERMISSIONS.READ}>
              <ListCollaboratorPage />
            </RoleProtected>
          } />
          <Route path=":id" element={
            <RoleProtected viewName={VIEW_NAMES.COLLABORATORS} permission={PERMISSIONS.READ}>
              <UpdateCollaboratorsPage />
            </RoleProtected>
          } />
          <Route path="crear" element={
            <RoleProtected viewName={VIEW_NAMES.COLLABORATORS} permission={PERMISSIONS.WRITE}>
              <CreateCollaboratorPage />
            </RoleProtected>
          } />
        </Route>
      </Route>

      <Route element={<RouteGuard />}>
        <Route path="roles" element={ <Layout /> }>
          <Route index element= {
            <RoleProtected viewName={VIEW_NAMES.ROLES} permission={PERMISSIONS.READ}>
              <ListRolesPage />
            </RoleProtected>
          }/>
          <Route path="asignar" element={
            <RoleProtected viewName={VIEW_NAMES.ROLES} permission={PERMISSIONS.READ}>
              <AssignationRolePage/>
            </RoleProtected>
          }/>
          <Route path="asignar/:id" element={
            <RoleProtected viewName={VIEW_NAMES.ROLES} permission={PERMISSIONS.READ}>
              <RoleUserAssign/>
            </RoleProtected>
          }/>
          <Route path="editar/:id" element={
            <RoleProtected viewName={VIEW_NAMES.ROLES} permission={PERMISSIONS.READ}>
              <UpdateRolePage/>
            </RoleProtected>
          }/>
          <Route path="crear" element={
            <RoleProtected viewName={VIEW_NAMES.ROLES} permission={PERMISSIONS.WRITE}>
              <CreateRolePage/> 
            </RoleProtected>
          }/>
        </Route>
      </Route>

    </Routes>
  );
}

export default App;
