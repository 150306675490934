import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { API_URL } from "../api-url";

export const getAllAccounts = async () => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/account/allaccounts`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}