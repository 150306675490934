import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderJumbotron from "../../components/HeaderJumbotron";
import MaterialTime from "./components/project-types/MaterialTime";
import { PROJECT_TYPE_KOH, PROJECT_TYPE_MT } from './utilities/project-values.utility';
import { DashboardTitles } from "../../config/config";
import KeyOnHand from "./components/project-types/KeyOnHand";
import { ProjectType } from "../../interfaces/project-types/project-type.model";
import { getAllProjectTypes } from "../../service/accounts/project-types.service";

const CreateProjectPage = () => {

  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const [isKOHVisible, setKOHVisible] = useState(false);
  const [isMTVisible, setMTVisible] = useState(false);

  const handleProjectTurn = (ev: any) => {
    if(ev.target.value === '') {
      setKOHVisible(false);
      setMTVisible(false)
      return;
    }
    if(ev.target.value === PROJECT_TYPE_KOH) {
      setKOHVisible(true);
      setMTVisible(false);
      return;
    }
    if(ev.target.value === PROJECT_TYPE_MT) {
      setKOHVisible(false);
      setMTVisible(true);
      return;
    }
  }

  useEffect(() => {
    getAllProjectTypes().then(res => {
      if(res.data) return setProjectTypes(res.data);
    })
  }, []);

  return (
    <>
      <HeaderJumbotron title={DashboardTitles.CREATE_PROJECT} />

      <div className="container-fluid pt-5">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/proyectos">
                <span style={{ color: '#FFA645' }}>
                  Dashboard Proyectos
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Crear Proyecto</li>
          </ol>
        </nav>

        <div className="card mt-5 mb-5 w-25c">
          <div className="card-body">
            <div className="col-4">
              <label htmlFor="projectType">Seleccione Tipo Proyecto:</label>
              <select name="projectType" className="form-select" onChange={handleProjectTurn}>
                <option value="">Tipo Proyecto</option>
                {
                  projectTypes.length > 0 &&
                  projectTypes.map(pt => (
                    <option key={pt.id} value={pt.id} disabled={!pt.isActive}>{pt.name}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>


        {
          (isKOHVisible) ? 
          // Formulario LLave En Mano
          <KeyOnHand /> : null
        }
        
        {
          (isMTVisible) ? 
          // Formulario Tiempo Material
          <MaterialTime /> : null
        }

        {/* Formulario Tipo Proyecto 3 */}
        {/* {
          (isMTVisible) ? 
          // Formulario Tipo Proyecto 3
          <ProyectoTipo 3 /> : null
        } */}

      </div>
    </>
  );
};

export default CreateProjectPage;
