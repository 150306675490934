import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { FormEmployee } from "../../pages/collaborators/models/FormEmployee.model";
import { API_URL } from "../api-url";

export const postEmployee = async (data: FormEmployee): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.post(`${API_URL}capcalc/employee/employees`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const putEmployee = async (data: FormEmployee, id: string): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.put(`${API_URL}capcalc/employee/employees/${id}`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const getEmployees = async (): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/employee/employees`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const getEmployeeById = async (id: string): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/employee/employees/${id}`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}