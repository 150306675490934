export enum AvailableCountryKeys {
  CHILE = 'CL'
};

export const Labels = {
  [AvailableCountryKeys.CHILE]:{
      titles: {
          PROJECT_DASHBOARD: "Dashboard de Proyectos",
          YOU_NEED_TO_CREATE: "¿Necesitas crear un proyecto?",
          PROJECT_LIST: "Listado de Proyectos",
          CREATE_PROJECT: "Crear Proyecto",
          CREATE_PARTNER: "Crear Colaborador",
          EDIT_PROJECT: "Editar Proyecto",
          SEE_PROJECT: "Ver Proyecto",
          SHOW_ALL_PROJECTS: "Mostrar todos los proyectos",
          COLLABORATOR_ASSIGNED_TO_THE_PROJECT:"Colaboradores asignados al proyecto",
          ASSIGNMENT_COLLABORATOR: "Asignar Colaborador",
          PROJECT_DETAIL: "Detalle del Proyecto",
          COLLABORATORS: "Dashboard de Colaboradores",
          CREATE_COLLABORATORS: "Crear Colaboradores",
          EDIT_COLLABORATOR: "Editar Colaborador",
          ITERATIONS: "Iteraciones",
          DETAIL: "Detalle",
          STEP_ONE: "Paso 1: Ingresar datos de Proyecto",
          STEP_TWO: "Paso 2: Planificación",
          NO_ITERATIONS:"Proyecto sin Iteraciones",
          DELETE_ROWS: "¿Desea eliminar fila?"
        },
        forms: {
          PROJECT_NAME: "Nombre",
          CONTACT: "Contacto",
          CUSTOMER: "Cliente",
          START_DATE: "Fecha de Inicio",
          FINISH_DATE: "Fecha de Finalización",
          PROJECT_RATE: "Tarifa Proyecto",
          PARTNER_EMAIL: "Correo Colaborador",
          ALLOCATION_PERCENTAGE: "Porcentaje de Asignación",
          ROLE: "Rol",
          STACKS: "Skills",
          STATE:"Estado",
          EMAIL:"E-mail",
          RATE:"Tarifa",
          ASSIGNMENT:"Asignación",
          SENIORITY: "Seniority",
          TOTAL_HOURS: "Horas Totales ",
          DAYS: "Días",
          SPRINT: "Sprint ",
          PLANNING : "Planning ",
          REVIEW: "Review ",
          DAILY: "Daily ",
          EFFECTIVE_DAYS_SPRINT: "Días Efectivos Sprint",
          SPRINTS: "Sprints",
          COLLABORATORS: "Colaboradores ",
          COLLABORATOR:"Colaborador",
          FINAL_SPRINT: "Sprint Final",
          PROJECT_TYPE:"Tipo de Proyecto",



          //Create Colaborators
          CI_TYPE: "Tipo de Documento",
          CI: "Identificador",
          COLABORATOR_NAME: "Primer Nombre",
          SECOND_NAME: "Segundo Nombre",
          THIRD_NAME: "Tercer Nombre",
          FIRST_SURNAME: "Apellido Paterno",
          SECOND_SURNAME: "Apellido Materno",
          NATIONALITY: "Nacionalidad",
          GENDER: "Género",
          PHONE_NUMBER: "Teléfono",
          EMERGENCY_NUMBER: "Teléfono",
          EMERGENCY_PHONENAME: "Nombre Contacto",
          BIRTHDATE: "Fecha de Nacimiento",
          ENTRYDATE: "Fecha de Ingreso",
          DEPARTUREDATE: "Fecha de Termino",
          COUNTRY: "País",
          ADDRESS:"Dirección",
          COMMUNE: "Comuna",
          REGION: "Región",
          AVAILABLEFORTRAVEL: "Disponibilidad Para Viajar",
          EMPLOYEE_STATE: "Estado"
        },
        buttons: {
          CREATE: "Crear",
          SAVE: "Guardar",
          MODIFY: "Modificar",
          REMOVE: "Eliminar",
          SEE: "Ver",
          EDIT: "Editar",
          ASSIGN_PARTNER: "Asignar Colaborador a Proyecto",
          ASSIGN:"Asignar",
          CLOSE:"Cerrar",
          CREATE_PROJECT: "Crear Proyecto",
          CREATE_COLLABORATORS:"Crear Colaborador",
          BY_ASSIGNING: "Por Asignar",
          SAVE_PROJECT:"Guardar Proyecto",
          ADD_ROW:"+ Agregar Fila",
          CONFIRM: "Confimar",
          NOT: "No",
          YES: "Sí",
        },

        badges: {
          REMOVED: "Eliminado"
          
        },
        
        messages: {
          CLICK_BOTOM_AND_REGISTER: "Dale click al botón y registrar todos los proyectos que necesites",
          
        },
        columns: {
          PROJECT_NAME: "Nombre",
          CONTACT: "Contacto",
          CUSTUMER: "Cliente",
          START_DATE: "Fecha Inicio",
          FINISH_DATE: "Fecha Finalización",
          PROJECT_RATE: "Tarifa Proyecto",
          PARTNER_EMAIL: "Correo",
          SERVICE_NUMBER_PARTNER: "Tarifa",
          DOCUMENT_TYPE_PARTNER: "Asignación",
          ROLE: "Rol",
          START_DATE_PARTNER: "Fecha de Inicio",
          FINISH_DATE_PARTNER: "Fecha de Finalización",
          SKILLS: "Skills",
          ACTION: "",
          COLLABORATORS_NAME: "Nombre",
          COLLABORATORS_SURNAME: "Apellido",
          ID: "Identificador",
          ENTRY_DATE: "Fecha de Ingreso",
          ADDRESS: "Dirección",
          PHONE: "Teléfono",
          SENIORITY:"Seniority",
          ITERATION:"Iteraciones",
          AVARAGE_ASSIGNMENT  :"Promedio de Asignación",
          ASSIGN:"Asignar",
         
        },
        selects: {
          SELECT: "Seleccione",
          SELECT_ACTIVE: "Activo",
          SELECT_FINISH: "Terminado",
          SELECT_DELETE: "Eliminado"
        }
  }
}
