import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { getAssignmentByProjectId, putAssignment } from '../../../service/assignment/assignment.service';
import { customAlert, toast } from '../../../service/notification/toast.service';
import { removeDuplicatesByProperty } from '../../../utilities/removeDuplicates.utility';
import { createAssignmentAdapter } from '../adapters/create-assignment.adapter';
import { FormAssignment } from '../models/form-assignment.model';
import { AssignmentData } from './project-types/KeyOnHand';

interface IIterationAssign {
  assignmentData: AssignmentData | null;
  editMode: boolean | null;
}

const IterationAssign: FC<IIterationAssign> = ({ assignmentData, editMode }) => {

  const [enableView, setEnableView] = useState<boolean>(false);
  const [blockState, setBlockState] = useState<boolean>(editMode ? true : false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const rowValidation = yup.object().shape({
    assigns: yup.array()
      .of(
        yup.object().shape({
          iterations: yup.array().of(
              yup.object().shape({
                value: yup.number()
                .typeError('Debe ingresar un número válido')
                .min(0, 'El valor debe ser positivo')
                .max(100, 'El valor debe ser menor o igual a 100')
            })
          )
        })
      )
  });

  const {
    control,
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(rowValidation),
  });

  const {
    fields,
    replace
  } = useFieldArray({ name: 'assigns', control });

  const setIterations = (data: any) => {

    if(assignmentData) {
      const assignIterations = data.assigns.map((assign: any) => {
        return assign.iterations.map((it: any) => ({
          iteration: it.id,
          asignationPercentage: it.value,
          id: it.iterationId,
          name: assign.name,
          firstSurname: assign.firstSurname ,
          workPositionName: assign.workPositionName ,
          seniorityName: assign.seniorityName ,
          employeeId: assign.employeeId ,
          workPosition: assign.workPosition ,
          skills: assign.skills ,
          seniority: assign.seniority ,
          // TODO PARCHE
          senority: assign.seniority,
          startDate: assign.startDate,
          endDate: assign.endDate,
          amount:assign.amount,
          currencyId: assign.currencyId
        }))
      }).flat();

      const formEditAssignment: FormAssignment = {
        projectId: assignmentData.projectId,
        assignments: assignIterations
      }

      console.log('Formulario Iterations', formEditAssignment);
      
  
      customAlert('¿Deseas las siguientes asignaciones?')
      .then(res => {
        if(res.isConfirmed) {
          dispatch(loaderShow());
          putAssignment(formEditAssignment).then(response => {
            dispatch(loaderDismiss());
            if(response.status === ServiceStatusKeys.SUCCESS) {
              setBlockState(true);
              navigate('/proyectos');
              dispatch(showToast({ message: 'Se han guardado las iteraciones exitosamente', type: 'success' }))
            }
            if(response.status === ServiceStatusKeys.FAIL)
            dispatch(showToast({ message: 'Hubo un error al guardar las iteraciones', type: 'error' }))
          })
        }
      });
    }

  }

  const handleAssigments = (assignmentResponse: any) => {
    console.log(assignmentResponse);

    const adaptedAssignments = assignmentResponse.assignments.map((assign: any) => createAssignmentAdapter(assign));
    const employees = removeDuplicatesByProperty(
      adaptedAssignments.map((employee: any) => ({ employeeId: employee.employeeId})), 
      'employeeId'
    );
    const mappedAssignmentF = employees.map(employee => {
      const filtered = adaptedAssignments.filter((x: any) => x.employeeId === employee.employeeId);
      return {
        ...employee,
        name: filtered[0].name,
        firstSurname: filtered[0].firstSurname,
        workPositionName: filtered[0].workPositionName,
        seniorityName: filtered[0].seniorityName,
        seniority: filtered[0].seniority,
        workPosition: filtered[0].workPosition,
        skills: filtered[0].skills,
        startDate: filtered[0].startDate,
        endDate: filtered[0].endDate,
        amount: filtered[0].amount,
        currencyId: filtered[0].currencyId,
        iterations: filtered.map((it: any) => ({
          iterationId: it.id,
          id: it.iteration,
          value: it.asignationPercentage
        }))
      }
    });
    
    return replace(mappedAssignmentF);
  }

  const loadAssignments = async (projectId: string) => {
    dispatch(loaderShow());
    const assignments = await getAssignmentByProjectId(projectId);
    if(assignments.data) handleAssigments(assignments.data);
    return dispatch(loaderDismiss());
  }

  useEffect(() => {
    if(assignmentData) {
      setEnableView(true);
      loadAssignments(assignmentData.projectId);
    }
  }, [assignmentData]);
  
  return (
    <>
      {
        enableView &&
        <form onSubmit={handleSubmit(setIterations)}>
          <div className="row">
            {
              getValues(`assigns`).length === 1 &&
              <div className="col-3"></div>
            }

            {
              fields.map((_, index) => {
                const field = `assigns[${index}]`;

                return (
                  <div key={`${field}.employeeId`} className={getValues(`assigns`).length > 1 ? `col d-flex flex-column justify-content-between` : `col-6 d-flex flex-column justify-content-center`}>
                    <div className="row text-center mb-3">
                      <span>{ getValues(`${field}.workPositionName`) } { getValues(`${field}.seniorityName`) }</span>
                      <span>
                        { `${
                          getValues(`${field}.name`) === '' ? '-' : getValues(`${field}.name`)
                        } ${
                          getValues(`${field}.firstSurname`) === '' ? '-' : getValues(`${field}.firstSurname`)
                        }` }
                      </span>
                    </div>
                    <div className='row d-flex flex-column gap-3'>
                      {
                        getValues(`${field}.iterations`).map((it: any, indx: number) => {
                          return (
                            <div key={it.id} >
                              <div className='d-flex flex-row align-items-center gap-2'>
                                <span>{indx + 1}</span>
                                <input 
                                  disabled={blockState}
                                  type="number" 
                                  className='form-control' 
                                  { ...register(`${field}.iterations[${indx}].value`)}
                                  onChange={(ev) => setValue(`${field}.iterations[${indx}].value`, ev.target.value)}
                                />
                                <span>%</span>
                              </div>
                              <ErrorMessage
                                errors={errors}
                                name={`${field}.iterations[${indx}].value`}
                                render={({ message }) => {
                                  console.log('Message', message);
                                  return <p className='form-text text-danger'>{message}</p>
                                }}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>

          <hr className='mt-4' />

          {
            editMode && 
            <>
              {
                blockState &&
                <div className="d-flex justify-content-end">
                  <button type='button' className='btn btn-primary w-25' onClick={() => setBlockState(false)}>Editar</button>
                </div>
              }

              {
                !blockState &&
                <div className="d-flex justify-content-end">
                  <button type='submit' className='btn btn-primary w-25' disabled={blockState}>Guardar</button>
                </div>
              }
            </>
          }

          {
            !editMode &&
            <div className="d-flex justify-content-end">
              <button type='submit' className='btn btn-primary w-25'>Guardar</button>
            </div>
          }
          
        </form>
      }
    </>
  )
}

export default IterationAssign