import axios, { AxiosRequestConfig } from "axios";
import { getLocalStorage, removeLocalStorage } from '../local-storage/storage.service';


export const GlobalReqInterceptor = () => {  
  const setTokenHeaders = (request: AxiosRequestConfig) => {
    const authToken = getLocalStorage("authData").token;

    if(authToken === '' || authToken === null || authToken === undefined) {
      removeLocalStorage('authData');
      window.location.href = '/';
    }

    const newHeaders = {
      Authorization: authToken,
      "Content-Type": "application/json"
    };
    request.headers = newHeaders;
    return request;
  };

  axios.interceptors.request.use(
    (request) => {
      if (request.url?.includes('assets')) return request;
      return setTokenHeaders(request);
    },
    (error) => {
      console.log('Hubo un error en el request', error);
    }
  );
}