import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { customAlert } from '../../../service/notification/toast.service';
import { getAllPermissions } from '../../../service/permissions/permissions.service';
import { postRolesPermissions } from '../../../service/roles-permissions/roles-permissions.service';
import { getAllViews } from '../../../service/views/views.service';
import { createPermissionAdapter } from '../adapters/create-permission.adapter';
import { FormRolePermission } from '../models/form-role-permission.model';
import { ViewPermission } from '../models/view-permission.model';
import { View } from '../models/view.model';
import AccessRowItem from './AccessRowItem';

interface IRoleAssign {
  roleId: string;
}

const RoleCreateAssign: FC<IRoleAssign> = ({ roleId }) => {
  const [viewList, setViewList] = useState<View[]>([]);
  const [formViewPermissions, setFormViewPermissions] = useState<ViewPermission[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlePermissionData = (data: ViewPermission[], viewId: string) => {
    if(formViewPermissions.map(perm => perm.viewId).includes(viewId)) {
      const filteredFVP = formViewPermissions.filter(perm => perm.viewId !== viewId);
      return setFormViewPermissions([...filteredFVP, ...data]);
    }

    if(!formViewPermissions.map(perm => perm.viewId).includes(viewId)) {
      return setFormViewPermissions([...formViewPermissions, ...data]);
    }
  }

  const handleSubmitPermissions = () => {
    const formRole: FormRolePermission = {accessId: roleId,viewPermision: formViewPermissions};

    customAlert('¿Estás seguro de guardar ésta información?').then(response => {
      if(formRole && response.isConfirmed) {
        dispatch(loaderShow());
        postRolesPermissions(formRole).then(response => {
          dispatch(loaderDismiss());
          if(response.status === ServiceStatusKeys.SUCCESS) {
            navigate('/roles');
            dispatch(showToast({ message: 'Se han asignado los roles exitosamente', type: 'success' }))
          }
          if(response.status === ServiceStatusKeys.FAIL) {
            dispatch(showToast({ message: 'Hubo un error al asignar los roles', type: 'error' }))
          }
        })
      }
    });
  }

  const loadViews = async () => {
    dispatch(loaderShow());
    const loadedViews = await getAllViews();

    if(loadedViews.status === ServiceStatusKeys.SUCCESS) {
      setViewList(loadedViews.data);
    }
    dispatch(loaderDismiss());
  }

  useEffect(() => {
    loadViews();
  }, []);

  useEffect(() => {
    if(viewList.length > 0) {
      dispatch(loaderShow());
      getAllPermissions().then(response => {
        dispatch(loaderDismiss());
        if(response.data){ 
          const adaptedPermissions = response.data.map((perm: any) => createPermissionAdapter(perm));
          const loadPermissions = viewList.map(view => {
            return adaptedPermissions.map((perm: any) => ({
              viewId: view.id,
              permissionId: perm.id,
              isActive: false
            }));
          }).flat();
          return setFormViewPermissions(loadPermissions);
        }
      })
    }
  }, [viewList]);

  return (
    <>
      <div className="row">
        <div className="col">

          <ul className='list-group mt-2 mb-2'>
            <li style={{ listStyle: 'none' }}>
              <div className="row">
                <div className="col-6">
                  Vistas
                </div>
                <div className="col-6">
                  Permisos
                </div>
              </div>
            </li>
          </ul>

          <ul className='list-group' id='viewList'>
            {
              viewList.length > 0 &&
              viewList.map(view => {
                return (
                  <AccessRowItem 
                    key={view.id}
                    viewId={view.id}
                    viewName={view.name}
                    setPermissions={handlePermissionData}
                  />
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button 
          type='button'
          className='btn btn-primary w-25' 
          onClick={handleSubmitPermissions}>
          Guardar
        </button>
      </div>
    </>
  )
}

export default RoleCreateAssign