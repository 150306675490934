import React from 'react';

interface IHeaderJumbotron {
  title: string;
}

const HeaderJumbotron: React.FC<IHeaderJumbotron> = ({ title }) => {
  return (
    <div className="bg-primary pb-10 pt-10 px-6">
      <h3 className="text-white">{ title }</h3>
    </div>
  )
}

export default HeaderJumbotron