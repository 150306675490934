//CONFIG_VERSION: v0.0.1
//AUTHOR:

import { AvailableCountryKeys, Labels } from "./dictionary";

export const ListProjectTableHeader = [
  Labels[AvailableCountryKeys.CHILE].columns.PROJECT_NAME,
  Labels[AvailableCountryKeys.CHILE].columns.CONTACT,
  Labels[AvailableCountryKeys.CHILE].columns.CUSTUMER,
  Labels[AvailableCountryKeys.CHILE].columns.START_DATE,
  Labels[AvailableCountryKeys.CHILE].columns.FINISH_DATE,
  Labels[AvailableCountryKeys.CHILE].columns.PROJECT_RATE,
  Labels[AvailableCountryKeys.CHILE].columns.ACTION,
];

export const ListCollaboratorsCreatedTableHeader = [
  Labels[AvailableCountryKeys.CHILE].columns.ID,
  Labels[AvailableCountryKeys.CHILE].columns.COLLABORATORS_NAME,
  Labels[AvailableCountryKeys.CHILE].columns.COLLABORATORS_SURNAME,
  Labels[AvailableCountryKeys.CHILE].columns.PARTNER_EMAIL,
  Labels[AvailableCountryKeys.CHILE].columns.ENTRY_DATE,
  Labels[AvailableCountryKeys.CHILE].columns.PHONE,
  Labels[AvailableCountryKeys.CHILE].columns.ACTION,
];

export const ListColaboratorTableHeader = [
  Labels[AvailableCountryKeys.CHILE].columns.PARTNER_EMAIL,
  Labels[AvailableCountryKeys.CHILE].columns.SERVICE_NUMBER_PARTNER,
  Labels[AvailableCountryKeys.CHILE].columns.DOCUMENT_TYPE_PARTNER,
  Labels[AvailableCountryKeys.CHILE].columns.ROLE,
  Labels[AvailableCountryKeys.CHILE].columns.SENIORITY,
  Labels[AvailableCountryKeys.CHILE].columns.SKILLS,
  Labels[AvailableCountryKeys.CHILE].columns.START_DATE_PARTNER,
  Labels[AvailableCountryKeys.CHILE].columns.FINISH_DATE_PARTNER,
];

export const ListRoadmapTableHeader = [
  Labels[AvailableCountryKeys.CHILE].columns.ROLE,
  Labels[AvailableCountryKeys.CHILE].columns.SENIORITY,
  Labels[AvailableCountryKeys.CHILE].columns.DOCUMENT_TYPE_PARTNER,
  Labels[AvailableCountryKeys.CHILE].columns.SERVICE_NUMBER_PARTNER,
];

export const ListModoalTableHeader = [
  Labels[AvailableCountryKeys.CHILE].columns.COLLABORATORS_NAME,
  Labels[AvailableCountryKeys.CHILE].columns.AVARAGE_ASSIGNMENT,
  Labels[AvailableCountryKeys.CHILE].columns.ASSIGN,

];




export const DashboardTitles = Labels[AvailableCountryKeys.CHILE].titles;
export const DashboardMessages = Labels[AvailableCountryKeys.CHILE].messages;
export const DashboardButtons = Labels[AvailableCountryKeys.CHILE].buttons;
export const DashboardForms = Labels[AvailableCountryKeys.CHILE].forms;
export const DashboardBadges = Labels[AvailableCountryKeys.CHILE].badges;
export const DashboardSelects = Labels[AvailableCountryKeys.CHILE].selects;
