import { UserAccess } from "../components/RoleUserAssign";

export const getUserAccessAdapter = (endpointUser: any): UserAccess => {
  const adaptedUserAccess: UserAccess = {
    userId: endpointUser.userId,
    userName: endpointUser.userName,
    accessId: endpointUser.accessId,
    accessName: endpointUser.accessName,
    isActive: endpointUser.isActive === 'activo' ? true : false,
  }

  return adaptedUserAccess;
}