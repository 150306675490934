import { AlertTitle, Snackbar, SnackbarOrigin } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { dismissToast } from "../redux/slices/toastSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastCustom = () => {
  const [position, setPosition] = useState<SnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal } = position;

  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.toast);
  
  const handleClose = () => {
    return dispatch(dismissToast());
  }

  return (
    <Snackbar 
      anchorOrigin={{ vertical, horizontal }}
      open={state.isActive} autoHideDuration={4000} onClose={handleClose}>
      {
        state.title ? 
        <Alert severity={state.type} onClose={handleClose} sx={{ width: '100%' }}>
          <AlertTitle>{state.title}</AlertTitle>
          {state.message}
        </Alert>:
        <Alert onClose={handleClose} severity={state.type} sx={{ width: '100%' }}>
          {state.message}
        </Alert>
      }
    </Snackbar>
  )
}

export default ToastCustom