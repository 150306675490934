import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { getRolesAccount } from '../../../service/accounts/role-account.service';
import { getAllSeniorities } from '../../../service/accounts/seniority.service';
import { getAssignmentByProjectId, getAssignPercentages, putAssignment, toogleAssignmentEmployee } from '../../../service/assignment/assignment.service';
import { getAllCurrencies } from '../../../service/currency/currency.service';
import { customAlert } from '../../../service/notification/toast.service';
import { getValorization } from '../../../service/valorization/valorization.service';
import { createArrayFromNumber } from '../../../utilities/create-array-methods.utility';
import { createCollaboratorAdapter } from '../../collaborators/adapters/create-collaborator.adapter';
import { createAssignmentRowAdapter } from '../adapters/create-assignment-row.adapter';
import { createRowArrayAdapter } from '../adapters/create-row-array.adapter';
import { Assignment } from '../models/assignment.model';
import { FormAssignment } from '../models/form-assignment.model';

interface IProjectEditAssign {
  setAssignmentData: Function;
}

const ProjectEditAssign: FC<IProjectEditAssign> = ({ setAssignmentData }) => {
  const { id } = useParams();
  const [accountId, setAccountId] = useState<string | null>(null);
  const [roleList, setRoleList] = useState([]);
  const [seniorityList, setSeniorityList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [blockState, setBlockState] = useState<boolean>(true);
  const [iterationLenght, setIterationlenght] = useState<number>(0);
  const [currentRole, setCurrentRole] = useState<string>('');
  const [currentSeniority, setCurrentSeniority] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const rowValidation = yup.object().shape({
    rows: yup.array()
      .of(
        yup.object().shape({
          workPosition: yup.string().required('Seleccione una opción'),
          seniority: yup.string().required('Seleccione una opción'),
          currencyId: yup.string().required('Seleccione una opción'),
          amount: yup.number()
          .typeError('Debe ingresar un número válido')
          .min(.1, 'La tarifa debe ser mayor a 0')
        })
      )
  });

  const { 
    control, 
    register, 
    formState: { errors }, 
    watch, 
    handleSubmit,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(rowValidation)
  });

  const { fields, append, remove, replace} = useFieldArray({
    control,
    name: 'rows'
  });

  const editAssignments = (data: any) => {
    if(id){
      data.rows = data.rows.map((assign: any) => {
        if(!assign.iterations) {
          return {
            ...assign,
            status: true,
            iterations: createArrayFromNumber(iterationLenght).map((x, index) => ({ id: index + 1, value: 0 }))
          }
        }
        if(assign.iterations) return assign
      });
  
      const mappedAssignments: Assignment[] = createAssignmentRowAdapter(data.rows);
  
      const formEditAssignments: FormAssignment = {
        projectId: id,
        assignments: mappedAssignments
      }

      customAlert('¿Deseas editar las siguientes asignaciones?')
      .then(res => {
        if(res.isConfirmed) {
          dispatch(loaderShow());
          putAssignment(formEditAssignments).then(response => {
            dispatch(loaderDismiss());
            if(response.status === ServiceStatusKeys.SUCCESS) {
              setAssignmentData({ projectId: id });
              navigate(0);
              setBlockState(true);
              dispatch(showToast({ message: 'Se han editado las asignaciones exitosamente', type: 'success' }))
            }
            if(response.status === ServiceStatusKeys.FAIL) {
              dispatch(showToast({ message: 'Hubo un error al editar las asignaciones', type: 'error' }))
            }
          })
        }
      });
    }
  }

  const handleDisableEmployee = async (index: number, status: boolean) => {
    if(id) {
      dispatch(loaderShow());
      const assignmentEmployee = await toogleAssignmentEmployee(id, getValues(`rows.${index}.employeeId`), status);
      dispatch(loaderDismiss());
      if(assignmentEmployee.data) {
        loadAssignments(id);
      }
    }
  }

  const setValorization = (data: any) => {
    return getValorization(data).then(response => {
      if(response.status === ServiceStatusKeys.SUCCESS) {
        setValue(`rows[${currentIndex}].amount`, response.data.tarifaBaseUf, { shouldValidate:true });
      }
      else {
        setValue(`rows[${currentIndex}].amount`, 0, { shouldValidate:true });
      }
    });
  }

  const loadProjectData = async (id: string) => {
    dispatch(loaderShow());
    const projectData = await getAssignmentByProjectId(id);
    const accountId = projectData.data.account.id;
    const roles = await getRolesAccount(accountId);
    const seniorities = await getAllSeniorities(accountId);
    const currencies = await getAllCurrencies(accountId);
    const employees = await getAssignPercentages();
    await loadAssignments(id);
    dispatch(loaderDismiss());

    if(projectData && roles && seniorities && currencies && employees && accountId) {
      setRoleList(roles.data);
      setSeniorityList(seniorities.data);
      setAccountId(accountId);
      setCurrencyList(currencies.data);
      setEmployeeList(employees.data.map((emp: any) => createCollaboratorAdapter(emp)));
      setIterationlenght(projectData.data.projectTotalIteration);
    }
  }

  const loadAssignments = async (projectId: string) => {
    const projectData = await getAssignmentByProjectId(projectId);
    const adaptedAssignments = createRowArrayAdapter(projectData.data.assignments);
    return replace(adaptedAssignments);
  }

  useEffect(() => {
    if(id) {
      loadProjectData(id);
    }
  }, []);

  useEffect(() => {
    if(currentRole && currentSeniority && currentCurrency) {
      setValorization({
        accountId: accountId,
        workPosition: currentRole,
        seniority: currentSeniority,
        currencyId: currentCurrency
      });
    }
  }, [currentRole, currentSeniority, currentCurrency]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const index = parseInt(name?.split('.')[1] ? name?.split('.')[1] : '-1');
      setCurrentIndex(index);
      if(name?.split('.')[2] === 'seniority') return setCurrentSeniority(value.rows[index].seniority || '');
      if(name?.split('.')[2] === 'workPosition') return setCurrentRole(value.rows[index].workPosition || '');
      if(name?.split('.')[2] === 'currencyId') return setCurrentCurrency(value.rows[index].currencyId || '');  
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <form onSubmit={handleSubmit(editAssignments)}>

        <div className='d-flex justify-content-between align-items-center mb-3'>
          <span>Recuerde completar todos los datos requeridos, el campo "Colaborador" es opcional</span>
          <button type='button' disabled={blockState} className='btn btn-primary w-25' onClick={() => append({})}>Agregar Colaborador</button>
        </div>
        
        <hr />
        
        <div className="row mb-3">
          <div className="col-2">
            <label>Rol</label>
          </div>
          <div className="col-2">
            <label>Seniority</label>
          </div>
          <div className="col-3">
            <label>Tarifa</label>
          </div>
          <div className="col-3">
            <label>Colaborador</label>
          </div>
          <div className="col-2 text-center">
            <label>Acciones</label>
          </div>
        </div>

        {
          fields.map((field, index) => {
            const datafield = `rows.${index}`;
            return (
              <div className='row mb-3' key={field.id}>
                <div className="col-2">
                  <select
                  className='form-select'
                  disabled={blockState}
                  {...register(`${datafield}.workPosition`)}
                  >
                    <option value=''>Seleccione Rol</option>
                    {
                      roleList.length > 0 &&
                      roleList.map((role: any) => (
                        <option key={role.id} value={role.id}>
                          { role.name }
                        </option>
                      ))
                    }
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name={`${datafield}.workPosition`}
                    render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                  />
                </div>
                <div className="col-2">
                  <select
                  className='form-select'
                    {...register(`${datafield}.seniority`)}
                    disabled={blockState}
                  >
                    <option value=''>Seleccione Seniority</option>
                    {
                      seniorityList.length > 0 &&
                      seniorityList.map((senior: any) => (
                        <option key={senior.id} value={senior.id}>
                          { senior.name }
                        </option>
                      ))
                    }
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name={`${datafield}.seniority`}
                    render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                  />
                </div>
                <div className="col-3">
                  <div className="input-group">
                    <select
                      className='input-group-text'
                      { ...register(`${datafield}.currencyId`)}
                      disabled={blockState}
                    >
                      <option value="">🪙</option>
                      {
                        currencyList.length > 0 &&
                        currencyList.map((currency: any) => (
                          <option key={currency.id} value={currency.id}>{currency.symbol}</option>
                        ))
                      }
                    </select>
                    <input
                      type="number"
                      disabled={blockState}
                      placeholder='Ej. 300'
                      className='radio-right-input form-control'
                      { ...register(`${datafield}.amount`)}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`${datafield}.currencyId`}
                      render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`${datafield}.amount`}
                      render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Autocomplete
                    options={employeeList}
                    size='small'
                    disabled={blockState}
                    isOptionEqualToValue={(value, option) => value ? value.employeeId === option.employeeId : false}
                    value={ 
                      getValues(`${datafield}.employeeId`) !== '' ? 
                      employeeList.find((em: any) => em.employeeId === getValues(`${datafield}.employeeId`)) ?? null : null
                    }
                    freeSolo={true}
                    clearOnBlur={false}
                    placeholder='Buscar Colaborador...'
                    onChange={(ev, newValue: any | null) => newValue ? setValue(`${datafield}.employeeId`, newValue.employeeId) : setValue(`${datafield}.employeeId`, '')}
                    getOptionLabel={(option: any) => `${option.name} ${option.firstSurname}` }
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {
                          option.asignationPercentage < 30 ? 
                          <Chip label={`${option.asignationPercentage}%`} color="success" /> : 
                          option.asignationPercentage >= 30 && option.asignationPercentage < 60 ? 
                          <Chip label={`${option.asignationPercentage}%`} color="warning" /> :
                          <Chip label={`${option.asignationPercentage}%`} color="error" />
                        }

                        <span style={{ marginLeft: '10px'}}>{`${option.name} ${option.firstSurname}`}</span>
                      </Box>
                    )}
                  />
                </div>
                <div className="col-2">
                  <div className='d-flex justify-content-center align-items-center'>
                    {
                      getValues(`${datafield}.employeeId`) && getValues(`${datafield}.iterations`) &&
                      <div className="form-check form-switch">
                        <input 
                          className="form-check-input"
                          type="checkbox"
                          disabled={blockState}
                          checked={getValues(`${datafield}.status`)}
                          onChange={(ev) => handleDisableEmployee(index, ev.target.checked)}
                        />
                      </div>
                    }
                    {
                      !getValues(`${datafield}.iterations`) &&
                      <button disabled={blockState} type='button' className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                        <i className="bi bi-x-lg"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            )
          })
        }

        <hr className='mt-4' />


        <div className='d-flex justify-content-end'>
          {
            blockState &&
            <button type='button' className='btn btn-primary w-25' onClick={() => setBlockState(false)}>
              Editar
            </button>
          }
          {
            !blockState &&
            <button type='submit' className='btn btn-primary w-25'>
              Guardar
            </button>
          }
        </div>


      </form>
    </>    
  )
}

export default ProjectEditAssign