import { Collaborator } from "../models/collaborator.model";

export const createCollaboratorAdapter = (endpointData: any): Collaborator => {

  const adaptedCollaborator: Collaborator = {
    asignationPercentage: endpointData.asignationPercentage,
    employeeId: endpointData.employeeID,
    firstSurname: endpointData.firstSurname,
    name: endpointData.name,
    project: endpointData.project,
    checked: false
  }

  return adaptedCollaborator;
}