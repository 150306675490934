export class Formulas {

  // constructor() {}

  daysPerHour(totalHours: number): number {
    return Math.round(totalHours / 8)
  }

  totalHours(days: number): number {
    return (days * 8);
  }

  ceremonyHours(daily: number, sprint: number, planning: number, review: number): number {
    return (daily * 5 * sprint + planning + review);
  }

  effectiveHours(ceremonyHours: number, sprint: number): number {
    return (40 * sprint - ceremonyHours);
  }

  effectiveDays(effectiveHours: number): number {
    return Math.trunc((effectiveHours / 8)*100) / 100;
  }

  calcEffectiveDays(daily: number, sprint: number, planning: number, review: number): number {
    let horasCeremonia = daily * 5 * sprint + planning + review;
    let horasEfectivas = 40 * sprint - horasCeremonia;
    let diasEfectivos = horasEfectivas / 8;
    return diasEfectivos;
  }

  sprintCalc(days: number, effectiveDays: number): number {
    return Math.round((days / effectiveDays) * 100) / 100;
  }

  finalSprintCalc(nSprint: number, nColaborator: number): number {
    return Math.round((nSprint / nColaborator) * 100) / 100;
  }
}