import { SSObject } from "../../interfaces/single-sign-on/sso-object.model";

export const createSSOAdapter = (endpointSSO: any): SSObject => {
  const adaptedSSObject: SSObject = {
    accessId: endpointSSO.accessId,
    email: endpointSSO.email,
    id: endpointSSO.idUser,
    image: endpointSSO.image,
    name: endpointSSO.name,
    token: endpointSSO.token,
    role: {
      // TODO Aplicar cambios al servicio
      name: endpointSSO.nameaccess,
      permissions: endpointSSO.ViewPermission
      .map((x: any) => ({ nameView: x.name, viewPermission: x.permissions }))
      .reduce((acc: any, item: any) => {
        acc[item.nameView.replaceAll(' ', '')] = item.viewPermission.map((y: any) => ({ name: y.name, isActive: y.isActive }));
        return acc;
      }, {}),
    }
  }

  return adaptedSSObject;
}