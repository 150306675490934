import HeaderJumbotron from '../../components/HeaderJumbotron'
import ListUser from './components/ListUser'

const AssignationRolePage = () => {
  return (
    <>
      <HeaderJumbotron title={'Dashboard de Asignaciones'} />

      <div className="container-fluid pt-5">

        <div className="card">
          <div className="card-body">
            <ListUser/>
          </div>
        </div>

      </div>
    </>
  )
}

export default AssignationRolePage