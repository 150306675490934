import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { getRolesAccount } from '../../../service/accounts/role-account.service';
import { getAllSeniorities } from '../../../service/accounts/seniority.service';
import { getAssignmentByProjectId, getAssignPercentages, postAssignment } from '../../../service/assignment/assignment.service';
import { getAllCurrencies } from '../../../service/currency/currency.service';
import { customAlert, toast } from '../../../service/notification/toast.service';
import { getValorization } from '../../../service/valorization/valorization.service';
import { createArrayFromNumber } from '../../../utilities/create-array-methods.utility';
import { createCollaboratorAdapter } from '../../collaborators/adapters/create-collaborator.adapter';
import { FormAssignment } from '../models/form-assignment.model';
import { ProjectAssignData } from './project-types/KeyOnHand';

interface IProjectAssign {
  projectData: ProjectAssignData | null;
  setAssignmentData: Function;
}

const ProjectAssign: FC<IProjectAssign> = ({ setAssignmentData, projectData }) => {

  const [accountId, setAccountId] = useState<string>(''); 
  const [collaboratorList, setCollaboratorList] = useState([]);
  const [seniorityList, setSeniorityList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [roleAccountList, setRoleAccountList] = useState([]);
  const [enableView, setEnableView] = useState<boolean>(false);
  const [blockState, setBlockState] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<string>('');
  const [currentSeniority, setCurrentSeniority] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentCurrency, setCurrentCurrency] = useState<string>('');
  const dispatch = useAppDispatch();

  const rowValidation = yup.object().shape({
    rows: yup.array()
      .of(
        yup.object().shape({
          roleAccount: yup.string().required('Seleccione una opción'),
          seniority: yup.string().required('Seleccione una opción'),
          currencyId: yup.string().required('Seleccione una opción'),
          amount: yup.number()
          .typeError('Debe ingresar un número válido')
          .min(.1, 'La tarifa debe ser mayor a 0')
        })
      )
  });

  const { 
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
    watch
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(rowValidation),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rows'
  });

  const assignCollaborators = (data: any) => {
    if(projectData) {
      const iterationsArray = createArrayFromNumber(projectData.iterations).map(it => ({ id: it, value: 0 }));
      const formData = data.rows;
  
      const mappedFormData = formData.map((fd: any) => iterationsArray.map(it => ({
        employeeId: fd.employeeId,
        asignationPercentage: it.value,
        workPosition: fd.roleAccount,
        skills: '-',
        senority: fd.seniority,
        amount: fd.amount,
        iteration: it.id,
        startDate: null,
        endDate: null,
        currencyId: fd.currencyId
      }))).flat();
  
      const formAssignment: FormAssignment = {
        projectId: projectData.projectId,
        assignments: mappedFormData
      }
  
      customAlert('¿Deseas las siguientes asignaciones?')
      .then(res => {
        if(res.isConfirmed) {
          dispatch(loaderShow());
          postAssignment(formAssignment).then(response => {
            if(response.status === ServiceStatusKeys.SUCCESS) {
              dispatch(loaderDismiss());
              setBlockState(true);
              setAssignmentData({ id: projectData.projectId, refreshData: true });
              dispatch(showToast({ message: 'Se han creado las asignaciones exitosamente', type: 'success' }))
            }
            if(response.status === ServiceStatusKeys.FAIL)
              dispatch(showToast({ message: 'Hubo un error al crear las asignaciones', type: 'error' }))
          })
        }
      });
    }
  }

  const setValorization = (data: any) => {
    return getValorization(data).then(response => {
      if(response.status === ServiceStatusKeys.SUCCESS) {
        setValue(`rows[${currentIndex}].amount`, response.data.tarifaBaseUf, { shouldValidate:true });
      }
      else {
        setValue(`rows[${currentIndex}].amount`, 0, { shouldValidate:true });
      }
    });
  }

  const loadDataset = async (id: string) => {
    dispatch(loaderShow());
    const projectData = await getAssignmentByProjectId(id);
    const accountId = projectData.data.account.id;
    
    const roles = await getRolesAccount(accountId);
    const seniorities = await getAllSeniorities(accountId);
    const currencies = await getAllCurrencies(accountId);
    const employees = await getAssignPercentages();
    dispatch(loaderDismiss());
    // console.log(roles, seniorities, currencies, employees);

    if(roles && seniorities && currencies && employees && accountId) {
      setAccountId(accountId);
      setRoleAccountList(roles.data);
      setSeniorityList(seniorities.data);
      setCurrencyList(currencies.data);
      setCollaboratorList(employees.data.map((emp: any) => createCollaboratorAdapter(emp)));
    }
  }

  useEffect(() => {
    if(projectData) {
      setEnableView(true);
      loadDataset(projectData.projectId);
    }
  }, [projectData]);

  useEffect(() => {
    if(currentRole && currentSeniority && currentCurrency) {
      setValorization({
        accountId: accountId,
        workPosition: currentRole,
        seniority: currentSeniority,
        currencyId: currentCurrency
      });
    }
  }, [currentRole, currentSeniority, currentCurrency]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const index = parseInt(name?.split('.')[0].split('')[5] || '-1');
      setCurrentIndex(index);
      if(name?.split('.')[1] === 'seniority') return setCurrentSeniority(value.rows[index].seniority || '');
      if(name?.split('.')[1] === 'roleAccount') return setCurrentRole(value.rows[index].roleAccount || '');
      if(name?.split('.')[1] === 'currencyId') return setCurrentCurrency(value.rows[index].currencyId || '');  
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {
        enableView &&
        <form onSubmit={handleSubmit(assignCollaborators)}>
          <div className="d-flex justify-content-between align-items-center">
            <span>Recuerde completar todos los datos requeridos, el campo "Colaborador" es opcional</span>
            <button className='btn btn-primary w-25' disabled={blockState} onClick={() => append({})}>Agregar Colaborador</button>
          </div>
    
          <hr />
    
          <div className="row text-center">
            <div className="col">Rol</div>
            <div className="col">Seniority</div>
            <div className="col-3">Tarifa</div>
            <div className="col-3">Colaborador</div>
            <div className="col">Acciones</div>
          </div>
    
          <hr />
    
          {
            fields.length > 0 && 
            fields.map((_, index) => {
              const field: any = `rows[${index}]`;
    
              return (
                <div key={_.id} className="row mb-2">
                  <div className="col">
                    <select 
                      className='form-select'
                      disabled={blockState}
                      { ...register(`${field}.roleAccount`)}
                    >
                      <option value="">Seleccione Rol</option>
                      {
                        roleAccountList.length > 0 &&
                        roleAccountList.map((role: any) => (
                          <option key={role.id} value={role.id}>{ role.name }</option>
                        ))
                      }
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name={`${field}.roleAccount`}
                      render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                    />
                  </div>
                  <div className="col">
                    <select 
                      className='form-select'
                      disabled={blockState}
                      { ...register(`${field}.seniority`)}
                    >
                      <option value="">Seleccione Seniority</option>
                      {
                        seniorityList.length > 0 &&
                        seniorityList.map((senior: any) => (
                          <option key={senior.id} value={senior.id}>{ senior.name }</option>
                        ))
                      }
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name={`${field}.seniority`}
                      render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                    />
                  </div>
                  <div className="col-3">
                    <div className="input-group">
                      <select
                        className='input-group-text'
                        { ...register(`${field}.currencyId`)}
                        disabled={blockState}
                      >
                        <option value="">
                          🪙
                        </option>
                        {
                          currencyList.length > 0 &&
                          currencyList.map((currency: any) => (
                            <option key={currency.id} value={currency.id}>{currency.symbol}</option>
                          ))
                        }
                      </select>
    
                      <input
                        type="number"
                        disabled={blockState}
                        className='radio-right-input form-control'
                        { ...register(`${field}.amount`)}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`${field}.currencyId`}
                      render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`${field}.amount`}
                      render={({ message }) => <p className='form-text text-danger'>{message}</p>}
                    />
                  </div>
                  <div className="col-3">
                  <Autocomplete
                    options={collaboratorList}
                    disabled={blockState}
                    { ...register(`${field}.employeeId`)}
                    onChange={(event: any, newValue: any) => {
                      setValue(`${field}.employeeId`, newValue.employeeId);
                    }}
                    size="small"
                    isOptionEqualToValue={(option: any, value: any) => option.employeeId === value.employeeId}
                    getOptionLabel={(option: any) => option.name+' '+option.firstSurname }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {
                          option.asignationPercentage < 30 ? 
                          <Chip sx={{ marginRight: '20px' }} label={`${option.asignationPercentage}%`} color='success' /> : 
                          (
                            (option.asignationPercentage >= 30 && option.asignationPercentage < 70) ? 
                            <Chip sx={{ marginRight: '20px' }} label={`${option.asignationPercentage}%`} color='warning' /> :
                            <Chip sx={{ marginRight: '20px' }} label={`${option.asignationPercentage}%`} color='error' />
                          )
                        }
                        
                        {`${option.name} ${option.firstSurname}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params}  />
                    )}
                  />
                  </div>
                  <div style={{ height: '40px' }} className="col-2 d-flex justify-content-center align-items-center gap-2">
                    <button 
                      disabled={blockState}
                      className='btn btn-danger btn-sm' 
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar Fila"
                      onClick={() => remove(index)}
                    >
                      <i className="bi bi-x-lg"></i>
                    </button>                
                  </div>
                  <hr className='mt-3'/>
                </div>
              )
            })
          }
    
          <div className="d-flex justify-content-end">
            <button type='submit' className='btn btn-primary w-25' disabled={blockState}>Guardar</button>
          </div>
        </form>
      }
    </>

  )
}

export default ProjectAssign