import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { FormAssignment } from "../../pages/projects/models/form-assignment.model";
import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { API_URL } from "../api-url";

export const getAssignPercentages = async (): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/assignment/percentage`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const toogleAssignmentEmployee = async (projectId: string, employeeId: string, checked: boolean): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.put(`${API_URL}capcalc/assignment/project/${projectId}/${employeeId}`, { 'active': checked }), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}

export const postAssignment = async (data: FormAssignment) => {
  try {
    return createServiceResponseAdapter(
      await axios.post(`${API_URL}capcalc/assignment/assignments`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
};

export const putAssignment = async (data: FormAssignment) => {
  try {
    return createServiceResponseAdapter(
      await axios.put(`${API_URL}/capcalc/assignment/project`, data), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
};

export const getAssignmentByProjectId = async (projectId: string) => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/project/projects/${projectId}`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}