import Swal, { SweetAlertIcon } from "sweetalert2";

export const customAlert = (title: string) => {
  return Swal.fire({
    title: title,
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Aceptar',
    denyButtonText: 'Cancelar',
    reverseButtons: true
  });
}

export const toast = (title: string, message: string, icon: SweetAlertIcon, timer?: number) => {
  return Swal.fire({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: timer ? timer : 6000, 
    icon: icon,
    title: title,
    text: message,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
}

export const customModal = (title: string, message: string, icon: SweetAlertIcon) => {
  return Swal.fire({
    icon: icon,
    title: title,
    text: message,
  })
  
}
