import { Permission } from "../models/permission.model";

export const createPermissionAdapter = (endpointData: any): Permission => {

  const adaptedPermission: Permission = {
    id: endpointData.id || '',
    name: endpointData.name || '',
    createdBy: endpointData.createdBy || '',
    updatedBy: endpointData.updatedBy || '',
    createdAt: endpointData.createdAt || '',
    updatedAt: endpointData.updatedAt || '',
    isActive: endpointData.isActive,
  };

  return adaptedPermission;
}