import moment from "moment";
import { Project } from "../models/project.model";

export const createProjectAdapter = (endpointData: any): Project => {
  const adaptedProject: Project = {
    id: endpointData.id,
    name: endpointData.projectName,
    startDate: moment.utc(endpointData.projectStartDate).format('YYYY-MM-DD'),
    endDate: moment.utc(endpointData.projectEndDate).format('YYYY-MM-DD'),
    amount: endpointData.projectAmount,
    accountId: endpointData.account.id,
    contactName: endpointData.projectContactName,
    owner: endpointData.projectOwner,
    creationDate: endpointData.projectCreationDate,
    modificationDate: endpointData.projectModificationDate,
    status: endpointData.projectStatus,
    totalHours: endpointData.projectTotalHours,
    totalDays: endpointData.projectTotalDays,
    iteration: endpointData.projectIteration,
    planning: endpointData.projectPlanning,
    review: endpointData.projectReview,
    daily: endpointData.projectDaily,
    daysIteration: endpointData.projectDaysIteration,
    iterationEmployee: endpointData.projectIterationEmployee,
    numberEmployee: endpointData.projectNumberEmployee,
    totalIteration: endpointData.projectTotalIteration,
    currencyId: endpointData.projectCurrency.id,
    typeId: endpointData.projectType.id,
    accountName: endpointData.account.name,
    currencySymbol: endpointData.projectCurrency.symbol,
    description: endpointData.description,
    serviceType: endpointData.serviceType?.id ||'',
  }

  return adaptedProject;
}