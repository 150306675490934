import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import HeaderJumbotron from "../../components/HeaderJumbotron";
import { DashboardTitles } from "../../config/config";
import IterationAssign from "./components/IterationAssign";
import { AssignmentData, ProjectAssignData } from "./components/project-types/KeyOnHand";
import ProjectEditAssign from "./components/ProjectEditAssign";
import UpdateProject from "./components/UpdateProject";

const UpdateProjectPage = () => {
  const { id } = useParams();
  const [headerBlock, setHeaderBlock] = useState<boolean>(true);
  const [projectData, setProjectData] = useState<ProjectAssignData | null>(null);
  const [assignmentData, setAssignmentData] = useState<AssignmentData | null>({
    projectId: id || '',
    refreshData: false
  });

  const handleHeaderBlock = (value: boolean) => {
    return setHeaderBlock(value);
  }

  const handleProjectUpdateData = (values: any) => {

    const projectValues: ProjectAssignData = {
      projectId: values.projectId,
      accountId: values.accountId,
      iterations:values.iterations
    }

    return setProjectData(projectValues);
  }

  const handleAssignmentData = (values: any) => {
    const projectValues: AssignmentData = {
      projectId: values.projectId,
      refreshData: true
    }

    return setAssignmentData(projectValues);
  }

  return (
    <>
      <HeaderJumbotron title={(headerBlock) ? DashboardTitles.SEE_PROJECT : DashboardTitles.EDIT_PROJECT} />

      <div className="container-fluid mt-6">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/proyectos">
                <span style={{ color: '#FFA645' }}>
                  Dashboard Proyectos
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              { (headerBlock) ? DashboardTitles.SEE_PROJECT : DashboardTitles.EDIT_PROJECT }
            </li>
          </ol>
        </nav>

        {/* Paso 1 */}
        <div className="card mb-5 mt-6">
          <div className="card-body">
            <h4>Datos de Proyecto</h4>
            <hr />
            <UpdateProject
              setProjectUpdateData={handleProjectUpdateData}
              blocked={headerBlock}
              setBlocked={handleHeaderBlock}
            />
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-body">
            <h4>Colaboradores Asignados</h4>
            <hr />
              <ProjectEditAssign
                setAssignmentData={handleAssignmentData}
              />
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-body">
            <h4>Iteraciones por Colaborador</h4>
            <hr />
            <IterationAssign
              assignmentData={assignmentData} 
              editMode={true}
            />
          </div>
        </div>
        
      </div>
    </>
  );
};

export default UpdateProjectPage;
