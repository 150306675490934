import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { useAppDispatch } from "../../../hooks/hooks";
import { ServiceStatusKeys } from "../../../interfaces/service-response/service-status-keys";
import { loaderDismiss, loaderShow } from "../../../redux/slices/loaderSlice";
import { showToast } from "../../../redux/slices/toastSlice";
import { toast } from "../../../service/notification/toast.service";
import { postRole } from "../../../service/roles/roles.service";
import { FormRole } from "../models/form-role.model";

interface IRoleCreateForm {
  handleStep: Function,
  setRoleId: Function
}

const RoleCreateForm: FC<IRoleCreateForm> = ({ handleStep, setRoleId }) => {

  const [blocked, setBlocked] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const roleValidationSchema = Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
    isActive: Yup.boolean().typeError('Debe elegir una opcion').required('Campo requerido'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRole>({
    resolver: yupResolver(roleValidationSchema)
  });

  const createRoleHeader = (data: FormRole) => {
    dispatch(loaderShow());
    postRole(data).then(response => {
      dispatch(loaderDismiss());
      if(response.status === ServiceStatusKeys.SUCCESS) {
        handleStep(true);
        setBlocked(true);
        setRoleId(response.data.id);
        dispatch(showToast({ message: 'Se han creado los roles exitosamente', type: 'success' }))
      }
      if(response.status === ServiceStatusKeys.FAIL) {
        dispatch(showToast({ message: 'Hubo un error al crear los roles', type: 'error' }))
      }
    });

  }

  return (
    <form onSubmit={handleSubmit(createRoleHeader)}>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Nombre Rol</label>
            <input 
              type="text" 
              disabled={blocked}
              { ...register('name') }
              className={`form-control ${
                errors.name ? "is-invalid" : ""
              }`}
              placeholder='Ej. Administrador, Analista, etc.'
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Estado</label>
            <select 
              disabled={blocked}
              {...register('isActive')}
              className={`form-control form-select ${
                errors.isActive ? "is-invalid" : ""
              }`}
            >              
              <option value="">Seleccione Estado</option>
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </select>
            <div className="invalid-feedback">{errors.isActive?.message}</div>
          </div>
        </div>
      </div>


      <div className='d-flex justify-content-end mt-2'>
        <button 
          type="submit" 
          className="btn btn-primary w-25" 
          disabled={blocked}>Guardar</button>
      </div>

    </form>
  )
}

export default RoleCreateForm