import { useState } from 'react';
import { Link } from 'react-router-dom';
import UpdateCollaboratorForm from './components/UpdateCollaboratorForm';
import HeaderJumbotron from '../../components/HeaderJumbotron';
import { DashboardTitles } from "../../config/config";

const UpdateCollaboratorsPage = () => {

  const [formBlock, setFormBlock] = useState<boolean>(true);

  return (
    <>
      <HeaderJumbotron title={(formBlock) ? 'Ver Colaborador' : DashboardTitles.EDIT_COLLABORATOR} />

      <div className="container-fluid pt-5">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/colaboradores">
                <span style={{ color: '#FFA645' }}>
                  Dashboard Colaboradores
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {(formBlock) ? 'Ver Colaborador' : DashboardTitles.EDIT_COLLABORATOR }
            </li>
          </ol>
        </nav>

        <div className="card mt-5 mb-7">
          <div className="card-body">
            <UpdateCollaboratorForm
              blocked={formBlock}
              setBlocked={setFormBlock}
            />
          </div>
        </div>

      </div>
    </>
  )
};

export default UpdateCollaboratorsPage;
