import axios from "axios";
import { createServiceResponseAdapter } from "../../adapter/service-response/service-response.adapter";
import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";
import { API_URL } from "../api-url";

export const getValorization = async (data: any): Promise<ServiceResponse> => {
  try {
    return createServiceResponseAdapter(
      await axios.get(`${API_URL}capcalc/valorization/${data.accountId}/${data.workPosition}/${data.seniority}/${data.currencyId}`), 
      ServiceStatusKeys.SUCCESS
    );
  } catch (error: any) {
    return createServiceResponseAdapter(
      error.response, 
      ServiceStatusKeys.FAIL
    );
  }
}
