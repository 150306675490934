import { useState } from "react";

interface Rows {
  id: number;
  rol: string;
  name: string;
  employeeId: string;
  seniority: string;
  asignation: number;
  tarifa: number;
  iterations: Array<any>;
}

const useShareableState = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [changeTypeInfo, setChangeTypeInfo] = useState("llaveEnMano");
  const [activeInput, setActiveInput] = useState(true);
  const [showIterations, setShowIterations] = useState(false);
  const [changeType, setChangeType] = useState("");
  const [iterations, setIterations] = useState<Array<any>>([
    { assignmentId: '', id: 1, value: 0 },
  ]);
  const [rows, setRows] = useState<Array<Rows>>([
    {
      id: 0,
      rol: "",
      seniority: "",
      asignation: 0,
      name: "",
      employeeId: "",
      tarifa: 0,
      iterations: iterations,
    },
  ]);
  const [accountId, setAccountId] = useState("");
  const [projectId, setProjectId] = useState("");
  return {
    loader, 
    setLoader,
    changeTypeInfo,
    setChangeTypeInfo,
    activeInput,
    setActiveInput,
    showIterations,
    setShowIterations,
    changeType,
    setChangeType,
    rows,
    setRows,
    iterations,
    setIterations,
    accountId,
    setAccountId,
    projectId,
    setProjectId,
  };
};

export default useShareableState;
