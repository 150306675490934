import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/hooks";
import { Employee } from '../../../interfaces/employee-class/employee.model';
import { ServiceStatusKeys } from "../../../interfaces/service-response/service-status-keys";
import { loaderDismiss, loaderShow } from "../../../redux/slices/loaderSlice";
import { showToast } from "../../../redux/slices/toastSlice";
import { getEmployeeById, putEmployee } from "../../../service/employee/employee.service";
import {
  customAlert
} from "../../../service/notification/toast.service";
import { checkRolePermissions } from "../../../utilities/check-role-permissions.utility";
import { PERMISSIONS } from "../../../utilities/permissions";
import { SOUTH_AMERICA_GENTILIC } from "../../../utilities/south-america-gentilic.utility";
import { VIEW_NAMES } from "../../../utilities/view-names";
import { createEmployeeAdapter } from "../adapters/create-employee.adapter";
import { createFormEmployee } from "../adapters/create-form-employee.adapter";
import { validationSchema } from "../utilities/collaborator-validation.utility";

interface IUpdateCollaboratorForm {
  blocked: boolean;
  setBlocked: Function;
}

const UpdateCollaboratorForm: React.FC<IUpdateCollaboratorForm> = ({
  blocked,
  setBlocked,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [employee, setEmployee] = useState<Employee>(new Employee());

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Employee>({
    resolver: yupResolver(validationSchema),
  });

  const handleUpdateEmployees = () => {
    if(id){
      customAlert(
        "¿Deseas guardar los cambios realizados en el formulario?"
      ).then((res) => {
        if (res.isConfirmed) {
          dispatch(loaderShow());
          putEmployee(createFormEmployee(employee), id).then((response) => {
            dispatch(loaderDismiss());
            if (response.status === ServiceStatusKeys.SUCCESS) {
              setBlocked(true);
              dispatch(showToast({ message: 'Se ha editado el colaborador exitosamente', type: 'success' }))
              // toast(
              //   "Editar Colaborador",
              //   "Se ha editado el colaborador exitosamente",
              //   "success"
              // );
            }
            if (response.status === ServiceStatusKeys.FAIL) {
              dispatch(showToast({ message: 'Hubo un error al editar al colaborador', type: 'error' }))
              // toast(
              //   "Editar Colaborador",
              //   "No se ha podido realizar la edición",
              //   "error"
              // );
            }
          });
        }
      });
    }
  };

  const handleEmployeeForm = (event: any) => {
    setEmployee((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const entryDateField = useController({ name: "entryDate", control });
  const departureDateField = useController({ name: "departureDate", control });

  const loadEmployee = async (id: string) => {
    dispatch(loaderShow());
    const loadedEmployee = await getEmployeeById(id);

    if(loadedEmployee.status === ServiceStatusKeys.SUCCESS) {
      setEmployee(createEmployeeAdapter(loadedEmployee.data));
    }

    return dispatch(loaderDismiss());
  }

  useEffect(() => {
    if(id) { loadEmployee(id); }
  }, []);

  useEffect(() => {
    reset(employee);
  }, [employee]);

  return (
    <form onSubmit={handleSubmit(handleUpdateEmployees)} className="p-3">
      <h4 className="mb-4">Identificación</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Tipo de Documento</label>
            <select
              className={`form-control form-select ${
                errors.CIType ? "is-invalid" : ""
              }`}
              {...register("CIType")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            >
              <option>Indique su tipo de documento</option>
              <option value="run">RUN</option>
              <option value="dni">DNI</option>
              <option value="passport">Pasaporte</option>
            </select>
            <div className="invalid-feedback">{errors.CIType?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Identificador</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.CI ? "is-invalid" : ""
              }`}
              {...register("CI")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.CI?.message}</div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Datos Personales</h4>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Primer Nombre</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.firstName ? "is-invalid" : ""
              }`}
              {...register("firstName")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Segundo Nombre</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.secondName ? "is-invalid" : ""
              }`}
              {...register("secondName")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <span className="form-text">Opcional</span>
            <div className="invalid-feedback">{errors.secondName?.message}</div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Tercer Nombre</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.thirdName ? "is-invalid" : ""
              }`}
              {...register("thirdName")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <span className="form-text">Opcional</span>
            <div className="invalid-feedback">{errors.thirdName?.message}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Apellido Paterno</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.firstSurname ? "is-invalid" : ""
              }`}
              {...register("firstSurname")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">
              {errors.firstSurname?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Apellido Materno</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.secondSurname ? "is-invalid" : ""
              }`}
              {...register("secondSurname")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">
              {errors.secondSurname?.message}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label className="form-label">Fecha de Nacimiento</label>
            <input
              type="date"
              className={`form-control form-control ${
                errors.birthDate ? "is-invalid" : ""
              }`}
              {...register("birthDate")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.birthDate?.message}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Nacionalidad</label>
            <select
              placeholder="Ingrese Nacionalidad"
              {...register("nationality")}
              className={`form-control form-select ${
                errors.nationality ? "is-invalid" : ""
              }`}
              disabled={blocked}
              onChange={handleEmployeeForm}
            >
              <option value="">Seleccione Nacionalidad</option>
              {SOUTH_AMERICA_GENTILIC.map((people, index) => (
                <option key={"gent" + index} value={people}>
                  {people}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {errors.nationality?.message}
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Género</label>
            <select
              className={`form-control form-select ${
                errors.gender ? "is-invalid" : ""
              }`}
              {...register("gender")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            >
              <option defaultValue="a">Indique su género</option>
              <option value="masculino">Masculino</option>
              <option value="femenino">Femenino</option>
              <option value="indefinido">Indefinido</option>
              <option value="otro">Otro</option>
            </select>
            <div className="invalid-feedback">{errors.gender?.message}</div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Contacto Colaborador</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">E-mail</label>
            <input
              type="email"
              className={`form-control form-control ${
                errors.email ? "is-invalid" : ""
              }`}
              {...register("email")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Teléfono</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.phoneNumber ? "is-invalid" : ""
              }`}
              {...register("phoneNumber")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">
              {errors.phoneNumber?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Domicilio</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Dirección</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.address ? "is-invalid" : ""
              }`}
              {...register("address")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Comuna</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.commune ? "is-invalid" : ""
              }`}
              {...register("commune")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.commune?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Región</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.region ? "is-invalid" : ""
              }`}
              {...register("region")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.region?.message}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">País</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.country ? "is-invalid" : ""
              }`}
              {...register("country")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">{errors.country?.message}</div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Contacto de Emergencia</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Nombre Contacto</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.emergencyPhoneName ? "is-invalid" : ""
              }`}
              {...register("emergencyPhoneName")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">
              {errors.emergencyPhoneName?.message}
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Teléfono</label>
            <input
              type="text"
              className={`form-control form-control ${
                errors.emergencyPhoneNumber ? "is-invalid" : ""
              }`}
              {...register("emergencyPhoneNumber")}
              disabled={blocked}
              onChange={handleEmployeeForm}
            />
            <div className="invalid-feedback">
              {errors.emergencyPhoneNumber?.message}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4 className="mb-4">Admisión</h4>
      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Fecha de Ingreso</label>
            <input
              type="date"
              className={`form-control form-control ${
                errors.entryDate ? "is-invalid" : ""
              }`}
              // {...register("entryDate")}
              disabled={blocked}
              value={entryDateField.field.value}
              onChange={(ev) => entryDateField.field.onChange(ev.target.value)}
            />
            <div className="invalid-feedback">{errors.entryDate?.message}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <label className="form-label">Fecha de Termino</label>
            <input
              type="date"
              className={`form-control form-control ${
                errors.departureDate ? "is-invalid" : ""
              }`}
              value={departureDateField.field.value}
              onChange={(ev) =>
                departureDateField.field.onChange(ev.target.value)
              }
              disabled={blocked}
              min={entryDateField.field.value}
            />
            <span className="form-text">Opcional</span>
            <div className="invalid-feedback">
              {errors.departureDate?.message}
            </div>
          </div>
        </div>
      </div>

      {checkRolePermissions(VIEW_NAMES.COLLABORATORS, PERMISSIONS.EDIT) && (
        <>
          <hr />
          <div className="d-flex justify-content-end">
            {blocked === true && (
              <button
                type="button"
                className="btn btn-primary w-25"
                onClick={() => setBlocked(false)}
              >
                Editar
              </button>
            )}

            {blocked === false && (
              <button type="submit" className="btn btn-primary w-25">
                Guardar
              </button>
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default UpdateCollaboratorForm;
