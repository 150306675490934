import { Assignment } from "../models/assignment.model";

export const createAssignmentRowAdapter = (endpointData: any): Assignment[] => {
  const adaptedAssignments: Assignment[] = endpointData.map((ep: any) => {
    return ep.iterations.map((it: any) => {
      const body = {
        name: ep.name || '',
        firstSurname: ep.firstSurname || '',
        workPositionName: ep.workPositionName || '',
        seniorityName: ep.seniorityName || '',
        employeeId: ep.employeeId || '',
        asignationPercentage: it.value || 0,
        workPosition: ep.workPosition || '',
        skills: ep.skills || '',
        senority: ep.seniority || '',
        startDate: ep.startDate || null,
        endDate: ep.endDate || null,
        amount: Number(ep.amount) || 0,
        iteration: it.id || 0,
        currencyId: ep.currencyId
      }

      if(it.assignmentId) {
        return {
          ...body,
          id: it.assignmentId
        }
      }

      return body;
    })
  }).flat();

  return adaptedAssignments;
}