import { Link } from "react-router-dom";
import HeaderJumbotron from "../../components/HeaderJumbotron";
import { DashboardButtons, DashboardTitles } from "../../config/config";
import { checkRolePermissions } from "../../utilities/check-role-permissions.utility";
import { PERMISSIONS } from "../../utilities/permissions";
import { VIEW_NAMES } from "../../utilities/view-names";
import ListCollaborator from "./components/ListCollaborators";

const ListCollaboratorPage = () => {
  return (
    <>
      <HeaderJumbotron title={DashboardTitles.COLLABORATORS}/>

      <div className="container-fluid pt-5">

        {
          checkRolePermissions(VIEW_NAMES.COLLABORATORS, PERMISSIONS.WRITE) &&
          <div className="d-md-flex justify-content-md-end mb-5">
            <Link to="crear">
              <button className="btn btn-primary mx-1" type="button">
                {DashboardButtons.CREATE_COLLABORATORS}
              </button>
            </Link>
          </div>
        }

        {/*Vista Colaboradores Creados */}
        <div className="card">
          <div className="card-body">
            <ListCollaborator />
          </div>
        </div>

      </div>
    </>
  );
}

export default ListCollaboratorPage;
