import { ServiceResponse } from "../../interfaces/service-response/service-response.model";
import { ServiceStatusKeys } from "../../interfaces/service-response/service-status-keys";

export const createServiceResponseAdapter = (endpointResponse: any, status: ServiceStatusKeys): ServiceResponse => {
  const adaptedResponse: ServiceResponse = {
    data: endpointResponse.data,
    status: status,
    code: endpointResponse.status
  }

  return adaptedResponse;
}