import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type ToastType = 'success' | 'info' | 'error';

interface ToastState {
  title?: string | null;
  isActive: boolean;
  message: string;
  type: ToastType
}

interface ToastContent {
  title?: string | null;
  message: string;
  type: ToastType
}

// Define the initial state using that type
const initialState: ToastState = {
  isActive: false,
  message: '',
  type: 'info'
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastContent>) => {
      (action.payload.title) ? state.title = action.payload.title : state.title = null;
      state.isActive = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    dismissToast: (state) => { state.isActive = false; },
  },
})

export const { showToast, dismissToast } = toastSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.toast;

export default toastSlice.reducer