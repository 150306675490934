import axios from "axios";
import { removeLocalStorage } from '../local-storage/storage.service';

export const AuthenticationInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      // Aqui se puede hacer algo con el response
      return response;
    }, 
    (error) => {
      if(error.response.status === 401) {
        console.log('Pasando por el interceptor');
        removeLocalStorage('authData');
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
}