import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import { ServiceStatusKeys } from '../../../interfaces/service-response/service-status-keys';
import { loaderDismiss, loaderShow } from '../../../redux/slices/loaderSlice';
import { showToast } from '../../../redux/slices/toastSlice';
import { customAlert, toast } from '../../../service/notification/toast.service';
import { putRolesPermissions } from '../../../service/roles-permissions/roles-permissions.service';
import { checkRolePermissions } from '../../../utilities/check-role-permissions.utility';
import { PERMISSIONS } from '../../../utilities/permissions';
import { VIEW_NAMES } from '../../../utilities/view-names';
import { FormRolePermission } from '../models/form-role-permission.model';
import { ViewPermission } from '../models/view-permission.model';
import { ViewA } from '../UpdateRolePage';
import AccessRowItemEdit from './AccessRowItemEdit';

interface IRoleEditAssign {
  viewList: ViewA[];
}

const RoleEditAssign: FC<IRoleEditAssign> = ({ viewList }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [blockState, setBlockState] = useState<boolean>(true);
  const [formViewPermissions, setFormViewPermissions] = useState<ViewPermission[]>([]);

  const handlePermissionData = (data: ViewPermission[], viewId: string) => {
    if(formViewPermissions.map(perm => perm.viewId).includes(viewId)) {
      const filteredFVP = formViewPermissions.filter(perm => perm.viewId !== viewId);
      return setFormViewPermissions([...filteredFVP, ...data]);
    }

    if(!formViewPermissions.map(perm => perm.viewId).includes(viewId)) {
      return setFormViewPermissions([...formViewPermissions, ...data]);
    }
  }

  const handleEditPermissions = () => {

    const updatePermissionsForm: FormRolePermission = {
      accessId: id || '',
      viewPermision: formViewPermissions
    }

    customAlert('¿Estás seguro de guardar ésta información?').then(response => {
      if(updatePermissionsForm && response.isConfirmed) {
        dispatch(loaderShow());
        putRolesPermissions(id || '', updatePermissionsForm).then(response => {
          dispatch(loaderDismiss());
          if(response.status === ServiceStatusKeys.SUCCESS) {
            navigate('/roles');
            dispatch(showToast({ message: 'Se ha editado la asignacion de roles exitosamente', type: 'success' }))
          }
          if(response.status === ServiceStatusKeys.FAIL) {
            dispatch(showToast({ message: 'Hubo un error al editar la asignacion de roles', type: 'error' }))
          }
        })
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className="col">

          <ul className='list-group mt-2 mb-2'>
            <li style={{ listStyle: 'none' }}>
              <div className="row">
                <div className="col-6">
                  Vistas
                </div>
                <div className="col-6">
                  Permisos
                </div>
              </div>
            </li>
          </ul>

          <ul className='list-group' id='viewList'>
            {
              viewList.length > 0 &&
              viewList.map(view => {
                return (
                  <AccessRowItemEdit 
                    key={view.id}
                    viewId={view.id}
                    viewName={view.name}
                    setPermissions={handlePermissionData}
                    editData={view.permissions}
                    blocked={blockState}
                  />
                )
              })
            }
          </ul>
        </div>
      </div>
      
      {
        checkRolePermissions(VIEW_NAMES.ROLES, PERMISSIONS.EDIT) &&
        <div className="d-flex justify-content-end mt-4">
          {
            blockState &&
            <button 
              type='button'
              className='btn btn-primary w-25' 
              onClick={() => setBlockState(false)}>
              Editar
            </button>
          }
          {
            !blockState &&
            <button 
              type='button'
              className='btn btn-primary w-25' 
              onClick={handleEditPermissions}>
              Guardar
            </button>
          }
        </div>
      }

    </>
  )
}

export default RoleEditAssign