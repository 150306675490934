import { useState } from 'react'
import { Link } from 'react-router-dom'
import HeaderJumbotron from '../../components/HeaderJumbotron'
import RoleCreateAssign from './components/RoleCreateAssign'
import RoleCreateForm from './components/RoleCreateForm'
import { ROLE_DICTIONARY } from '../../config/role-dictionary'

const DICTIONARY = {
  ...ROLE_DICTIONARY.CREATE_ROLE,
  ...ROLE_DICTIONARY.ASSIGN_ROLE,
  ...ROLE_DICTIONARY.BREADCRUMBS_ROLES 
};

const CreateRolePage = () => {
  const [stepTwo, setStepTwo] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<string>('');

  const handleRoleId = (data: string) => {
    return setRoleId(data);
  }

  return (
    <>
      <HeaderJumbotron title={DICTIONARY.JUMBOTRON_LABEL} />

      <div className="container-fluid pt-5">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/roles">
                <span style={{ color: '#FFA645' }}>
                  {DICTIONARY.ROOT_URI}
                </span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">{DICTIONARY.FIRST_URI}</li>
          </ol>
        </nav>

        <div className="card mt-5">
          <div className="card-body">
            <h4>{DICTIONARY.STEP_CREATE_HEADER_LABEL}</h4>
            <hr />
            <RoleCreateForm
              handleStep={ setStepTwo }
              setRoleId={handleRoleId}
            />
          </div>
        </div>

        {
          (stepTwo) ? 
          <div className="card mt-5">
            <div className="card-body">
              <h4>{DICTIONARY.STEP_ASSIGN_HEADER_LABEL}</h4>
              <hr />
              <RoleCreateAssign
                roleId={roleId}
              />
            </div>
          </div> : null
        }

        

      </div>
    </>
  )
}

export default CreateRolePage;